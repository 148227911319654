@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS yang Document 2020.03
Last Update 2020.03 yang
---------------------------------*/
/*
共通
-------------------------------------*/
.linkBoxWrap {
  width: 100%;
  .linkBoxIn {
    display: flex;
    flex-wrap: wrap;
    .linkBox {
      width: 100%;
      height: 230px;
      margin-bottom: 1px;
      a {
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        &:hover { text-decoration: none; }
      }
      .boxImg {
        height: 180px;
        position: relative;
        overflow: hidden;
        .img {
          min-width: 375px;
          height: 100%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
        span {
          position: absolute;
          width: 80px;
          height: 80px;
          background-color: rgba(255, 255, 255, .95);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 15px;
          left: 15px;
        }
      }
      .boxText {
        height: 50px;
        text-align: center;
        line-height: 54px;
        background: url(../img/contents/arrow_01.png) no-repeat top 20px right 15px;
        background-size: 20px 7px;
        p {
          display: inline-block;
          font-size: 18px;
          color: #fff;
          letter-spacing: .15em;
          margin: 0;
        }
      }
      &:first-of-type {
        background-color: #1da39c;
        .boxImg {
          .img { background-image: url(../img/contents/top/img_01_sp.jpg); }
          span img { width: 45px; }
        }
      }
      &:nth-of-type(2) {
        background-color: #62ab4f;
        .boxImg {
          .img { background-image: url(../img/contents/top/img_02_sp.jpg); }
          span img { width: 53px; }
        }
      }
      &:last-of-type {
        background-color: #3288be;
        margin-bottom: 50px;
        .boxImg {
          .img { background-image: url(../img/contents/top/img_03_sp.jpg); }
          span img { width: 41px; }
        }
      }
    }
  }
  .imgBtn {
    width: 100%;
    height: 220px;
    overflow: hidden;
    a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
    }
    .btnBg {
      width: 100%;
      min-width: 375px;
      height: 100%;
      background: url(../img/contents/top/img_04.jpg) center no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
    }
    .pageBtn {
      position: absolute;
      width: 275px;
      height: 60px;
      line-height: 62px;
      background-color: rgba(70, 174, 114, .95);
      background-position: top 26px right 15px;
      right: 15px;
      bottom: 15px;
      span {
        display: inline;
        font-size: 18px;
        letter-spacing: .155em;
        margin-left: -25px;
      }
    }
  }
}
.accessBoxWrap {
  background-color: #e4f1f7;
  .accessBoxIn {
    width: 100%;
    .boxTitle {
      width: 100%;
      text-align: center;
      h3 {
        display: inline-block;
        width: 162px;
        height: 63px;
        font-size: 20px;
        line-height: 73px;
        letter-spacing: .15em;
        background: url(../img/contents/circle_02.png) no-repeat center right;
        background-size: 71px auto;
        margin-left: .15em;
      }
    }
    .boxContent {
      margin-top: 27px;
      letter-spacing: .05em;
      position: relative;
      &:before {
        position: absolute;
        display: block;
        content: '';
        width: 153px;
        height: 166px;
        background: url(../img/contents/illust_03.png) no-repeat top left;
        background-size: 153px auto;
        top: -68px;
        left: -80px;
      }
      .googleMap {
        height: 345px;
        border-radius: 8px;
        border: 4px solid #fff;
        position: relative;
        z-index: 2;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .mapLink {
        display: block;
        height: 100%;
        text-align: right;
        margin-top: 10px;
        a {
          display: inline-block;
          font-size: 14px;
          line-height: 1.15;
          letter-spacing: .05em;
          padding-right: 23px;
          background: url(../img/contents/icon_link_bl.png) no-repeat top right;
          background-size: 14px auto;
          transform: translateY(1px);
        }
      }
      .boxTextWrap {
        margin-top: 40px;
        h4 {
          font-size: 18px;
          line-height: 1;
        }
        .wayLists {
          margin-top: 18px;
          .wayList {
            padding-left: 21px;
            line-height: 1.75;
            position: relative;
            &:before {
              position: absolute;
              content: '';
              width: 9px;
              height: 9px;
              background-color: #46ae72;
              border-radius: 50%;
              top: 9px;
              left: 0;
            }
          }
        }
        .boxSubText {
          margin-top: 24px;
          padding: 28px 27px 21px;
          border-radius: 8px;
          border: 3px solid #cbdfe8;
          h5 {
            font-size: 16px;
            line-height: 1;
            padding-left: 31px;
            background: url(../img/contents/icon_07.png) no-repeat center left;
            background-size: 24px auto;
          }
          p {
            margin: 0;
            margin-top: 10px;
            font-size: 14px;
            line-height: 1.86;
          }
        }
      }
    }
  }
  .noticeBox {
    margin-top: 61px;
    padding: 45px 15px 39px;
    background-color: #7ca8bd;
    color: #fff;
    p { margin: 0; }
    .noticeLists {
      margin-top: 24px;
      .noticeList {
        font-size: 14px;
        line-height: 1.72;
        padding-left: 21px;
        position: relative;
        &:before {
          position: absolute;
          content: '※';
          width: 13px;
          height: 13px;
          line-height: 13px;
          top: 5px;
          left: 0;
        }
      }
    }
  }
}
.tableWrap {
  table {
    tr {
      width: 100%;
      &:nth-of-type(2) {
        td { letter-spacing: 0;}
      }
    }
    th {
      width: 37.6%;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      font-weight: normal;
    }
    td {
      padding: 16px 15px;
      line-height: 1.75;
    }
  }
}
.listWrap {
  position: relative;
  z-index: 1;
  .list {
    margin-top: 15px;
    &:first-of-type { margin-top: 0; }
    .listCont {
      display: block;
      width: 100%;
      background-color: #fff;
      background: url(../img/contents/arrow_03.png) no-repeat bottom 40px right 26px;
      background-size: 40px auto;
      border: 4px solid #c9e4d5;
      border-radius: 14px;
      padding: 51px 26px 61px;
      letter-spacing: .05em;
    }
    .listTime {
      font-size: 14px;
      color: #888;
      line-height: 1;
    }
    .listTitle {
      display: block;
      font-size: 20px;
      color: #2188b4;
      line-height: 1.6;
    }
    p {
      font-size: 16px;
      color: #222;
      line-height: 1.75;
      margin: 0;
      margin-top: 8px;
    }
  }
}
.entryWrap {
  .entryIn{
    padding: 50px 26px 49px;
    background-color: #fff;
    border: 4px solid #c9e4d5;
    border-radius: 14px;
    position: relative;
    z-index: 2;
    p {
      line-height: 1.75;
      text-align: justify;
      margin: 0;
      margin-top: 29px;
    }
  }
  .entryTime {
    display: inline-block;
    font-size: 14px;
    color: #888;
    line-height: 1;
    margin-bottom: 14px;
  }
  .entryTitle {
    display: block;
    font-size: 20px;
    line-height: 1.6;
    padding-bottom: 13px;
    border-bottom: 1px solid #46ae72;
    margin-bottom: 39px;
  }
}
.pageEntry .btnWrap {
  margin: 45px auto 0;
  width: calc(100% - 30px);
  height: 45px;
  line-height: 45px;
  position: relative;
  text-align: center;
  letter-spacing: .05em;
  font-size: 14px;
  .button {
    display: inline-block;
    width: 109px;
    letter-spacing: .2em;
    background-color: #46ae72;
    border-radius: 8px;
    position: absolute;
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
    span {
      position: relative;
      background-size: 22px auto;
      background-repeat: no-repeat;
      color: #fff;
    }
  }
  .prev {
    left: 0;
    span {
      padding-left: 35px;
      background-image: url(../img/contents/arrow_02.png);
      background-position: left 4px;
      left: -5px;
    }
  }
  .next {
    right: 0;
    span {
      padding-right: 30px;
      background-image: url(../img/contents/arrow_01.png);
      background-position: right top 4px;
      right: -8px;
    }
  }
  .textBtn { display: inline-block; }
}
.formWrap {
  margin-top: 39px;
  .formIn {
    width: 100%;
    tr {
      border-bottom: 1px solid #e5e5e5;
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      &:first-of-type { border-top: 1px solid #e5e5e5; }
      &:last-of-type td span { width: 100%; }
    }
    th {
      font-weight: normal;
      vertical-align: top;
      &:before {
        display: inline-block;
        width: 44px;
        height: 26px;
        font-size: 14px;
        color: #fff;
        line-height: 26px;
        letter-spacing: .15em;
        text-align: center;
        border-radius: 4px;
        margin-right: 10px;
      }
      &.any:before {
        content: '任意';
        background-color: #bbbaba;
      }
      &.must:before {
        content: '必須';
        background-color: #e23435;
      }
      small {
        font-size: 14px;
        color: #888;
        margin-left: 5px;
      }
    }
    td {
      width: 100%;
      margin-top: 8px;
      input, textarea {
        width: 100%;
        background-color: #eeeeee;
        border-radius: 4px;
        padding: 8px 15px 6px;
        &::placeholder {
          color: #888;
          opacity: 1;
        }
        &::-webkit-input-placeholder { /* WebKit browsers */
        color: #888;
        opacity: 1;
        }
        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: #888;
        opacity: 1;
        }
        &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: #888;
        opacity: 1;
        }
        &:-ms-input-placeholder { /* Internet Explorer 10+ */
        color: #888;
        opacity: 1;
        }
      }
      textarea {
        height: 230px;
        resize: vertical;
      }
      small {
        font-size: 14px;
        color: #888;
        display: inline-block;
        margin-top: 5px;
      }
      label {
        display: inline-block;
        margin-right: 21px;
        margin-bottom: 10px;
        padding-top: 8px;
        padding-left: 50px;
        line-height: 1.65;
        position: relative;
          .radio {
            position: absolute;
            top: 1px;
            left: 0;
          }
        &:nth-of-type(4) { margin-right: 33px; }
        small {
          display: inline;
          margin: 0;
          position: relative;
          left: 5px;
        }
      }
    }
    .radio {
      width: 40px;
      height: 40px;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      top: -1px;
      span {
        display: inline-block;
        width: inherit;
        height: inherit;
        background-color: #eee;
        border-radius: 50%;
        position: relative;
        &.checked:before {
          position: absolute;
          content: '';
          width: 12px;
          height: 12px;
          background-color: #46ae72;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        input { width: inherit; height: inherit; }
      }
    }
  }
  //wp contact form 7
  .wpcf7-form-control-wrap {
    display: inline-block;
  }
  .wpcf7-radio {
    display: inline-block;
    height: 100%;
  }
  span.wpcf7-form-control-wrap {
    width: 100%;
    .wpcf7-checkbox {
      .wpcf7-list-item {
        display: block;
        margin: 0 0 10px 0;
        &:nth-of-type(2) { margin-left: 50px; }
        &:last-of-type { margin-bottom: 0; }
      }
    }
    input.wpcf7c-conf,
    textarea.wpcf7c-conf {
      border: none;
      background-color: transparent;
      height: auto;
    }
    input.wpcf7c-conf::-webkit-input-placeholder { color: #fff !important; }
    input.wpcf7c-conf::-moz-placeholder { color: #fff !important; }
    input.wpcf7c-conf:-ms-input-placeholder { color: #fff !important; }
    textarea.wpcf7c-conf::-webkit-input-placeholder { color: #fff !important; }
    textarea.wpcf7c-conf::-moz-placeholder { color: #fff !important; }
    textarea.wpcf7c-conf:-ms-input-placeholder { color: #fff !important; }
  }
  .sendBtn {
    margin-top: 50px;
    text-align: center;
    .button, .wpcf7-submit {
      width: 285px;
      height: 70px;
      line-height: 72px;
      background-color: #46ae72;
      font-size: 18px;
      color: #fff;
      letter-spacing: .2em;
      border-radius: 8px;
      opacity: 1;
      transition: opacity .3s ease;
      &:hover { opacity: .7; }
    }
    .textbtn, .textBack {
      display: inline-block;
      font-size: 14px;
      color: #2188b4;
      line-height: 1;
      margin-top: 31px;
    }
  }
}
.pageThanks {
  #ContBox01 {
    .contSubBox {
      p {
        margin: 0;
        line-height: 1.75;
      }
      span {
        color: #888;
        line-height: 1.75;
      }
      .telWrap {
        margin: 37px 30px 0;
        border-top: 1px solid #46ae72;
        border-bottom: 1px solid #46ae72;
        text-align: center;
        padding: 27px 0 21px;
        a {
          display: inline-block;
          color: #222;
        }
        p {
          display: inline-block;
          font-size: 30px;
          line-height: 1 !important;
          letter-spacing: .1em;
          &:before {
            display: inline-block;
            content: '';
            width: 28px;
            height: 28px;
            background: url(../img/contents/icon_tel.png) no-repeat center;
            background-size: 28px auto;
            transform: translate(5px, 4px);
            margin-right: 13px;
          }
        }
        span {
          display: inline-block;
          font-size: 14px;
          line-height: 1;
          transform: translateY(-1px);
        }
      }
      .topBtn {
        display: block;
        width: 285px;
        height: 50px;
        line-height: 52px;
        background-color: #46ae72;
        border-radius: 8px;
        text-align: center;
        margin: 48px auto 0;
        span {
          display: inline-block;
          font-size: 18px;
          color: #fff;
          line-height: 1;
          letter-spacing: .2em;
        }
        &:hover { opacity: .7; }
      }
    }
  }
}

/*
トップページ
-------------------------------------*/
#Page.pageIndex {
  #MainImg {
    .innerBasic {
      height: 425px;
      .mainImgBg {
        background-image: url(../img/contents/top/mainimg_sp.jpg);
        background-position: center bottom;
      }
      .textArea {
        width: 100%;
        max-width: 375px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        display: flex;
        justify-content: center;
        .text {
          z-index: 2;
          width: 141px;
          height: 123px;
          background-image: url(../img/contents/top/bg_02.png);
          background-size: 141px auto;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: absolute;
          top: 58px;
          right: 31px;
          span {
            display: block;
            font-size: 14px;
            letter-spacing: .075em;
            line-height: 2;
            transform: translate(2px, -5px);
            small { background: linear-gradient(transparent 60%, #fffaa6 60%); }
          }
        }
        .title {
          width: 330px;
          height: 133px;
          background: url(../img/contents/top/bg_01.png) no-repeat bottom left;
          background-size: 330px auto;
          color: #fff;
          transform: translateY(148.5px);
          span {
            display: inline-block;
            font-size: 18px;
            letter-spacing: .6em;
            line-height: 1;
            margin-top: 44px;
            margin-right: -.6em;
          }
          h2 {
            display: inline-block;
            letter-spacing: .3em;
            margin-right: -.3em;
            margin-top: 4px;
          }
        }
      }
      .import {
        position: absolute;
        width: calc(100% - 30px);
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        .importIn {
          display: flex;
          font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
          font-weight: 500;
          .title {
            background-color: #dd4545;
            font-size: 14px;
            color: #fff;
            line-height: 1.45;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
          }
          .cont {
            width: calc(100% - 87.45px;);
            padding: 11px 15px;
            text-align: left;
            .contLists { width: 100%; }
            .contList {
              width: 100%;
              height: 48px;
              display: block;
              margin-top: 7px;
              &:first-of-type { margin-top: 0; }
              a { display: block; }
            }
            time {
              font-size: 14px;
              line-height: 1;
              color: #888;
            }
            span {
              line-height: 1.5;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }
  #Main {
    #ContBox01 {
      background: url(../img/contents/bg_01.jpg) top left;
      background-size: 100px auto;
      &:before {
        position: absolute;
        content: '';
        width: 219px;
        height: 168px;
        background: url(../img/contents/illust_01.png) top left no-repeat;
        background-size: 219px auto;
        left: -70px;
        transform: translateY(-50px);
      }
      .contSubBox {
        padding-top: 32px;
        padding-bottom: 60px;
        .vrTextWrap {
          width: 100%;
          display: flex;
          justify-content: center;
          .vrText {
            width: auto;
            height: 320px;
            margin-left: 47px;
            &:first-of-type { margin-left: 0; }
            img {
              width: auto;
              height: 100%;
            }
          }
          .catchText {
            width: 100%;
            max-width: 375px;
            height: 100%;
            padding-top: 42px;
            display: flex;
            justify-content: center;
            background: url(../img/contents/top/bg_03.png) right 9px top 1px no-repeat;
            background-size: 298px auto;
            margin: 0 auto;
            .vrText {
              display: inline-block;
              &:first-of-type { height: 257px; }
            }
          }
        }
        .textWrap {
          margin-top: 40px;
          p {
            letter-spacing: .025em;
            line-height: 1.75;
            margin-bottom: 28px
          }
        }
        .pageBtn {
          width: 210px;
          margin: 37px auto 0;
        }
      }
    }
    #ContBox02 {
      background: url(../img/contents/bg_01.jpg) top left;
      background-size: 100px auto;
    }
    #ContBox03 {
      padding-top: 45px;
      padding-bottom: 72px;
      a.button {
        display: block;
        width: 275px;
        height: 60px;
        line-height: 62px;
        background-position: right 15px top 26px;
        padding-right: 11px;
        margin: 0 auto 46px;
        span {
          font-size: 18px;
        }
      }
      .contSubBox {
        .subBoxTitle {
          width: 100%;
          height: 55px;
          border-bottom: 1px solid #34a664;
          h3 {
            display: inline;
            font-size: 20px;
            line-height: 1;
            letter-spacing: .15em;
            position: relative;
            top: 22px;
            &:after {
              position: absolute;
              content: '';
              background-position: top left;
              background-repeat: no-repeat;
            }
          }
        }
      }
      .contSubBox01 {
        .subBoxTitle {
          h3 {
            &:after {
              width: 46px;
              height: 46px;
              background-image: url(../img/contents/illust_10.png);
              background-size: 46px auto;
              right: -53px;
              bottom: -2px;
            }
          }
        }
        .subBoxIn {
          .listWrap {
            width: 100%;
            overflow: hidden;
            .list {
              padding: 24px 0;
              border-bottom: 1px solid #e5e5e5;
              margin-top: 0;
              time {
                font-size: 14px;
                color: #888;
                line-height: 1;
                letter-spacing: .05em;
              }
              a {
                display: block;
                position: relative;
                span{
                  line-height: 1.625;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                }
              }
            }
          }
          .pageBtn {
            width: 170px;
            position: relative;
            left: calc(100% - 170px);
            margin-top: 30px;
            span { margin-left: -25px; }
          }
        }
      }
      .contSubBox02 {
        margin-top: 37px;
        .subBoxTitle {
          h3 {
            &:after {
              width: 35px;
              height: 34px;
              background-image: url(../img/contents/icon_03_bl.png);
              background-size: 35px auto;
              right: -43px;
              bottom: 2px;
            }
          }
        }
        .subBoxIn {
          .calender {
            width: 100%;
            height: 540px;
            margin-top: 29px;
            iframe { width: 100%; height: 100%; }
          }
          small {
            display: block;
            font-size: 14px;
            color: #888;
            letter-spacing: 0;
            line-height: 1.7;
            text-align: justify;
            margin-top: 27px;
          }
        }
      }
    }
    #ContBox04 {
      .contSubBox {
        padding-top: 33px;
      }
    }
  }
}

/*
霊園のご紹介
-------------------------------------*/
#PageIntroduction.pageIndex {
  img { width: 100%; }
  #MainImg {
    .innerBasic {
      .mainImgBg {
        background-image: url(../img/contents/introduction/mainimg_01.jpg);
      }
      h2 {
        margin-left: .15em;
        transform: translateY(1px);
        &:after {
          width: 145px;
          height: 106px;
          background-image: url(../img/contents/circle_01.png);
          background-size: 145px auto;
          top: -43px;
          right: -53px;
        }
      }
    }
  }
  #Container {
    background: url(../img/contents/bg_01.jpg) top left;
    background-size: 100px auto;
  }
  #Main {
    margin-top: 35px;
    .contBox {
      h3 {
        width: 100%;
        text-align: center;
        span {
          height: 63px;
          display: inline-block;
          font-size: 20px;
          line-height: 74px;
          letter-spacing: .15em;
          margin-left: calc(.15em + 21px);
          padding-right: 23px;
          background: url(../img/contents/circle_02.png) no-repeat center right;
          background-size: 71px auto;
        }
      }
    }
    #ContBox01 {
      .contSubBox {
        margin-top: 28px;
        padding-bottom: 24px;
        .linkBox {
          &:first-of-type {
            .boxImg {
              span {
                img { width: 45px; }
              }
            }
          }
          &:nth-of-type(2) {
            .boxImg {
              span {
                img { width: 53px; }
              }
            }
          }
          &:last-of-type {
            .boxImg {
              span {
                img { width: 41px; }
              }
            }
          }
        }
      }
    }
    #ContBox02 {
      background-color: #fff;
      padding-top: 49px;
      h3 {
        span {
          padding-right: 15px;
          margin-left: calc(.15em + 12px);
        }
      }
      .contSubBox {
        margin-top: 13px;
        .mapImg {
          display: block;
          margin-top: 43px;
        }
        small {
          display: block;
          margin-top: 10px;
          text-align: center;
          font-size: 12px;
          color: #888;
          line-height: 1;
        }
      }
      .imgBox {
        width: 100%;
        margin-top: 43px;
        img { width: 100%; }
      }
    }
    #ContBox03 {
      background-color: #fff;
      padding-top: 48px;
      padding-bottom: 75px;
      h3 {
        span {
          padding-right: 35px;
          margin-left: calc(.15em + 32px);
        }
      }
      .pdfBtn {
        margin-top: 13px;
        width: 180px;
      }
      .contSubBox {
        padding: 0;
        margin-top: 1px;
        .click {
          width: 100%;
          color: #2188b4;
          background-color: #f7f5e9;
          background: url(../img/contents/icon_open_bl.png) no-repeat center right 15px;
          background-size: 20px auto;
          padding: 22px 45px 23px 15px;
          h4 {
            font-size: 18px;
            line-height: 1.44;
          }
          &.active {
            color: #222;
            background-image: url(../img/contents/icon_close.png);
          }
        }
        .showBox {
          padding: 30px 15px 59px;
          .showBoxIn {
            width: 100%;
            table {
              width: 100%;
              margin-top: 45px;
              &:first-of-type { margin-top: 0; }
              table-layout: fixed;
              caption {
                width: 100%;
                height: 50px;
                background-color: #46ae72;
                border: 1px solid #d7d7c6;
                font-size: 16px;
                color: #fff;
                text-align: center;
                line-height: 50px;
                letter-spacing: .1em;
              }
              tr {
                width: 100%;
                border: 1px solid #d7d7c6;
                border-top: none;
                &.firstLine {
                  th {
                    border-left: 1px solid #d7d7c6;
                    &:first-of-type { border-left: 0; }
                  }
                }
              }
              th {
                width: calc(100% - 33.3%);
                background-color: #f7f5e9;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.75;
                vertical-align: middle;
                padding: 13px 12px 12px;
                span {
                  font-size: 12px;
                  color: #46ae72;
                }
              }
              td {
                width: 33.3%;
                border-left: 1px solid #d7d7c6;
                font-size: 14px;
                text-align: center;
                vertical-align: middle;
                &.colorBlock {
                  background-color: #fcfbf5;
                  padding: 7px 0 6px;
                }
              }
            }
            .scrollBox {
              overflow-x: scroll;
              table {
                width: 500px !important;
                caption {
                  text-align: left;
                  padding-left: 147px;
                }
              }
            }
            .listArea {
              margin-top: 11px;
              .list {
                font-size: 14px;
                color: #888;
                text-align: justify;
                line-height: 1.7;
                margin-top: 2px;
              }
            }
            span.note {
              font-size: 14px;
              line-height: 1.7;
              margin-bottom: 2px;
            }
          }
        }
      }
      .contSubBox01 {
        margin-top: 45px;
        .showBox {
          .showBoxIn{
            table:first-of-type {
              tr.firstLine { background-color: #f7f5e9; }
            }
            table:last-of-type {
              caption {
                height: 35px;
                line-height: 35px;
              }
              tr {
                &.firstLine {
                  background-color: #f7f5e9;
                  th {
                    width: 33.3%;
                    text-align: center;
                    padding: 6px 12px 5px;
                  }
                  td { background-color: transparent;}
                }
                &:nth-of-type(2),&:last-of-type {
                  td {
                    padding: 12px 15px;
                    text-align: justify;
                    font-size: 12px;
                    line-height: 1.7em;
                  }
                }
              }
            }
          }
        }
      }
      .contSubBox02 {
        .showBox {
          padding-bottom: 54px;
          .showBoxIn {
            .scrollBox {
              table {
                tr {
                  &.firstLine th {
                    padding: 14px 12px 12px;
                    text-align: center;
                    &:first-of-type { text-align: left; }
                  }
                  &:nth-of-type(n+3) {
                    td {
                      &:first-of-type {
                        text-align: left;
                        padding: 14px 12px;
                      }
                    }
                  }
                }
                th {
                  &:first-of-type {
                    text-align: left;
                    width: 33.3%;
                  }
                }
                td { width: 20%; }
              }
            }
            table.second {
              margin-top: 45px;
              caption {
                height: auto;
                line-height: 1.625;
                padding: 5px 0 2px;
              }
              tr {
                &.firstLine {
                  th {
                    background-color: #f7f5e9;
                    padding: 6px 0 5px;
                  }
                }
              }
              th {
                padding: 5px 0;
                text-align: center;
                background-color: #fcfbf5;
              }
            }
          }
        }
      }
      .contSubBox03 {
        .showBox {
          padding-bottom: 56px;
          .showBoxIn {
            .scrollBox {
              table {
                tr.firstLine {
                  td {
                    background-color: #fcfbf5;
                    padding: 14px 0;
                  }
                }
                th { width: 33.3%; }
              }
            }
            table.second {
              margin-top: 31px;
              caption {
                height: 63px;
                line-height: 1.625;
                letter-spacing: .05em;
                padding: 5px 0;
              }
              tr.firstLine {
                td {
                  background-color: #fcfbf5;
                  padding-bottom: 5px;
                }
              }
              th {
                padding: 0 12px;
                width: 33.3%;
              }
              td {
                padding: 7px 0 6px;
              }
            }
            .listArea .list { margin-top: 1px; }
            .textArea {
              margin-top: 20px;
              .left {
                ul {
                  width: 100%;
                  li {
                    line-height: 1.75;
                    padding-left: 15px;
                    position: relative;
                    &:before {
                      position: absolute;
                      content: '';
                      width: 8px;
                      height: 8px;
                      border-radius: 50%;
                      background-color: #46ae72;
                      top: 9px;
                      left: 0;
                    }
                  }
                }
                span {
                  display: block;
                  margin-top: 3px;
                  font-size: 14px;
                  color: #46ae72;
                  line-height: 1.75;
                }
              }
              .right {
                margin-top: 18px;
                display: flex;
                justify-content: space-between;
                dl {
                  width: calc( calc(100% - 2px) / 3);
                  dt {
                    width: 100%;
                    img { width: 100%; }
                  }
                  dd {
                    text-align: center;
                    font-size: 12px;
                    line-height: 1.33;
                    padding-top: 10px;
                  }
                }
              }
            }
          }
        }
      }
      .contSubBox04 {
        .showBox {
          padding-top: 31px;
          padding-bottom: 55px;
          .showBoxIn {
            .itemArea {
              .item {
                display: flex;
                margin-top: 15px;
                &:first-of-type { margin-top: 0; }
                &:last-of-type { margin-top: 16px; }
                dt {
                  width: 52%;
                }
                dd {
                  width: 48%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  padding-left: 30px;
                  font-size: 18px;
                  line-height: 1.44;
                  small {
                    font-size: 14px;
                    color: #888;
                    position: relative;
                    top: 3px;
                  }
                }
              }
            }
            .listArea { margin-top: 26px; }
          }
        }
      }
      .contSubBox05 {
        .showBox {
          padding-bottom: 0;
          .showBoxIn {
            .scrollBox {
              table {
                tr.firstLine {
                  th { text-align: left; }
                  td { background-color: #fcfbf5; }
                }
                th { width: 33.3%; }
              }
            }
            table.cleaning {
              margin-top: 30px;
              caption {
                height: auto;
                line-height: 1.625;
                padding: 5px 0 4px;
              }
              tr:first-of-type td.colorBlock { padding-bottom: 5px; }
              tr:last-of-type {
                th { padding: 3px 19px 1px; }
              }
              th { width: 33.3%; }
              &:last-of-type {
                margin-top: 41px;
                tr:last-of-type th { padding: 6px 19px 3px; }
              }
            }
          }
        }
      }
      .pdfBtn_large {
        margin-top: 45px;
        width: 285px;
        height: 60px;
        line-height: 60px;
        span {
          font-size: 18px;
          background-size: 23px auto;
          padding-right: 32px;
        }
      }
    }
    #ContBox04 {
      .contSubBox {
        padding-top: 34px;
        .boxTitle h3 { background-position: center right 42px; }
        .boxTextWrap {
          .boxInfo {
            background-color: #fff;
            padding: 30px 30px 15px;
            border-radius: 8px;
            .infoLeft {
              text-align: center;
              img { width: 195px; }
              p {
                font-size: 14px;
                line-height: 1;
                text-align: center;
                margin: 0;
                margin-top: 20px;
              }
            }
            .infoRight {
              margin-top: 29px;
              .telBtns {
                background-color: transparent;
                display: flex;
                justify-content: space-between;
                padding-bottom: 15px;
                border-bottom: 1px solid #d4d4d4;
                .telBtn {
                  width: calc( calc(100% - 10px) / 2);
                  a {
                    display: block;
                    width: 100%;
                    padding: 15px 0 12px;
                    font-size: 14px;
                    line-height: 1.285;
                    border-radius: 8px;
                    span {
                      display: block;
                      font-size: 16px;
                      line-height: 1;
                      margin-top: 3px;
                    }
                  }
                  &:first-of-type a {
                    color: #046fb3;
                    background-color: #d2e8f5;
                  }
                  &:last-of-type a {
                    color: #4ba634;
                    background-color: #d8f6d0;
                  }
                }
                &:after { display: none; }
              }
              .opening {
                font-size: 12px;
                color: #888;
                line-height: 1;
                text-align: center;
                margin-top: 15px;
              }
            }
          }
          .boxText { margin-top: 45px; }
        }
      }
      .noticeBox {
        margin-top: 66px;
        padding-bottom: 35px;
      }
    }
    #ContBox05 {
      background-color: #fff;
      padding-bottom: 120px;
      padding: 0 15px 120px;
      h3{
        margin-bottom: 23px;
        span{
          width: 280px;
          letter-spacing: .15em;
        }
      }
      .lead{
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        line-height: 2.0;
        margin-bottom: 29px;
      }
      .contSubBox {
        border: 4px solid #c9e4d5;
        border-radius: 12px;
        padding: 40px 25px 35px;
        .boxWrap{
          margin-bottom: 35px;
          max-height: 585px;
          overflow: hidden;
          transition: max-height 1.0s;
          &.is_open{
            max-height: 1700px;
          }
          .imgBox{
            width: 230px;
            height: auto;
            margin: 0 auto 35px;
            img{
              width: 100%;
              height: auto;
            }
          }
          .infoBox{
            h4{
              font-size: 24px;
              font-weight: 700;
              color: #07853c;
              margin: 6px 0;
            }
            .labelBox{
              margin-bottom: 32px;
              span{
                background-color: #46ae72;
                border-radius: 4px;
                color: #fff;
                padding: 2px 9px;
                display: inline-block;
                font-size: 14px;
                letter-spacing: 0.04em;
              }
            }
            p{
              line-height: 2.0;
            }
            dl{
              margin-top: 35px;
              margin-bottom: 21px;
              dt{
                font-size: 20px;
                font-weight: 700;
                color: #46ae72;
                margin-bottom: 4px;
              }
              dd{
                font-size: 16px;
                font-weight: 500;
                line-height: 2.0;
              }
            }
            .officeLogo{
              display: inline-block;
              img{
                width: 206px;
                height: auto;
              }
            }
          }
        }
        .toggleBtn{
          font-size: 18px;
          font-weight: 500;
          color: #2188b4;
          background-color: #efeeea;
          border-radius: 8px;
          width: 100%;
          padding: 16px 0;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
}

/*■■■■■下層ページ■■■■■*/
#PageIntroduction.pageKazenomori,
#PageIntroduction.pageSancgarden,
#PageIntroduction.pageSora,
#PageDaichi.pageIndex {
  position: relative;
  #MainImg {
    .innerBasic {
      height: 220px;
      h2 {
        width: 130px;
        height: 130px;
        background-color: rgba(255, 255, 255, .95);
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        font-size: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  #Main {
    .contBox {
      padding-bottom: 67px;
      h3 {
        font-size: 20px;
        text-align: center;
        line-height: 1;
        letter-spacing: .15em;
      }
      .contSubBox {
        p {
          line-height: 1.75;
          margin-bottom: 28px;
        }
      }
    }
    #ContBox01 {
      margin-top: 54px;
      background-repeat: no-repeat;
      h3 {
        font-size: 22px;
        line-height: 1.6;
      }
      .contSubBox {
        margin-top: 33px;
        p:last-of-type { margin-bottom: 0; }
      }
    }
    #ContBox02 {
      background-color: #fff;
      padding-top: 75px;
      padding-bottom: 100px;
      h3 {
        position: relative;
        display: inline-block;
        left: 50%;
        transform: translateX(-50%);
        span {
          position: relative;
          z-index: 1;
        }
        &:after {
          position: absolute;
          content: '';
          width: 71px;
          height: 63px;
          background-size: 71px auto;
          background-position: top left;
          background-repeat: no-repeat;
          top: -26px;
          right: -35px;
        }
      }
      .contSubBox {
        margin-top: 40px;
        .galleryWrap {
          margin-top: 63px;
          h4 {
            display: block;
            padding-top: 11px;
            padding-bottom: 8px;
            border-bottom: 1px solid #34a664;
            span {
              position: relative;
              font-size: 20px;
              line-height: 1;
              letter-spacing: .15em;
              &:after {
                position: absolute;
                content: '';
                width: 40px;
                height: 34px;
                background: url(../img/contents/icon_08.png) center no-repeat;
                background-size: 40px auto;
                top: -8px;
                right: -43px;
              }
            }
          }
          .galleryIn {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 30px;
            .photo {
              width: calc( calc(100% - 2px) / 3);
              margin-right: 1px;
              &:nth-of-type(3n) { margin-right: 0; }
              &:nth-of-type(n+4) { margin-top: 1px; }
              a {
                position: relative;
                display: block;
                width: 100%;
                height: 100%;
                img { width: 100%; }
                &:after {
                  position: absolute;
                  content: '';
                  width: 23px;
                  height: 23px;
                  border-radius: 50%;
                  background-color: rgba(255, 255, 255, .9);
                  background: url(../img/contents/icon_09.png) center no-repeat;
                  background-size: 9px auto;
                  right: 5px;
                  bottom: 5px;
                  z-index: 2;
                }
              }
            }
          }
        }
      }
    }
    #ContBox03 {
      background: url(../img/contents/bg_02.jpg) top left;
      background-size: 75px auto;
      margin-top: 0;
      padding: 75px 0;
      position: relative;
      .contSubBox {
        margin-top: 38px;
        &:before {
          position: absolute;
          content: '';
          width: 115px;
          height: 167px;
          background: url(../img/contents/illust_01.png) no-repeat top right;
          background-size: 215px auto;
          top: -52px;
          left: 0;
        }
        p { margin-bottom: 0; }
        .contactBtn {
          display: block;
          margin-top: 23px;
          width: 100%;
          height: 60px;
          line-height: 64px;
          text-align: center;
          border-radius: 8px;
          background-color: #fff;
          // background: url(../img/contents/icon_link_org.png) no-repeat center right 15px;
          // background-size: 18px auto;
          p {
            display: inline;
            color: #ee9e3c;
            font-size: 18px;
            position: relative;
            left: -5px;
            &:before {
              display: inline-block;
              content: '';
              width: 34px;
              height: 33px;
              background: url(../img/contents/icon_03_org.png) no-repeat top left;
              background-size: 34px auto;
              transform: translate(-15px, 10px);
            }
          }
        }
      }
    }
  }
}

/*■■■■■風の森聖地■■■■■*/
#PageIntroduction.pageKazenomori {
  #MainImg {
    .innerBasic {
      .mainImgBg { background-image: url(../img/contents/introduction/mainimg_02.jpg); }
      h2 {
        background-image: url(../img/contents/logo_01.png);
        background-size: 68px auto;
      }
    }
  }
  #Container { background-color: #e1f3f2; }
  #Main {
    #ContBox01 {
      background-image: url(../img/contents/introduction/bg_kazenomori.png);
      background-size: 500px auto;
      background-position: right bottom;
      h3 { color: #009e97; }
    }
    #ContBox02 {
      h3 {
        &:after { background-image: url(../img/contents/circle_03.png); }
      }
      .contSubBox {
        .tableWrap {
          table {
            tr { border: 1px solid #cdd8d7; }
            th {
              background-color: #ebf8f7;
              border-right: 1px solid #cdd8d7;
            }
            td {
              small {
                display: block;
                font-size: 14px;
                color: #888;
              }
            }
          }
        }
      }
    }
  }
}
/*■■■■■サンクガーデン メモリアル 16■■■■■*/
#PageIntroduction.pageSancgarden {
  #MainImg {
    .innerBasic {
      .mainImgBg { background-image: url(../img/contents/introduction/mainimg_03.jpg); }
      h2 {
        background-image: url(../img/contents/logo_02.png);
        background-size: 80px auto;
      }
    }
  }
  #Container { background-color: #e6fbe0; }
  #Main {
    #ContBox01 {
      background-image: url(../img/contents/introduction/bg_sanc.png);
      background-size: 155px auto;
      background-position: right -30px bottom -2px;
      h3 { color: #4ca635; }
    }
    #ContBox02 {
      padding-bottom: 85px;
      h3 {
        &:after { background-image: url(../img/contents/circle_04.png); }
      }
      .contSubBox {
        table {
          tr { border: 1px solid #d2dacf; }
          th {
            background-color: #eff8eb;
            border-right: 1px solid #d2dacf;
          }
        }
        .galleryWrap {
          h4 { padding-bottom: 9px; }
        }
      }
    }
  }
}
/*■■■■■永代供養墓 そら■■■■■*/
#PageIntroduction.pageSora {
  #MainImg {
    .innerBasic {
      .mainImgBg { background-image: url(../img/contents/introduction/mainimg_04.jpg); }
      h2 {
        background-image: url(../img/contents/logo_03.png);
        background-size: 60px auto;
      }
    }
  }
  #Container {
    background-image: url(../img/contents/introduction/bg_sora.jpg);
    background-size: auto 506px;
    background-position: top center;
    background-repeat: no-repeat;
  }
  #Main {
    #ContBox01 {
      margin-top: 55px;
      .foreword {
        padding: 0 15px;
        line-height: 1.75;
        margin-bottom: 32px;
      }
      h3 { color: #2975a5; }
    }
    #ContBox02 {
      padding-bottom: 99px;
      h3 {
        &:after {
          background-image: url(../img/contents/circle_05.png);
          top: -27px;
        }
      }
      .contSubBox {
        table {
          tr {
            border: 1px solid #d2d8dd;
            &:nth-of-type(2) {
              td {
                a {
                  display: inline-block;
                  padding-right: 28px;
                  background: url(../img/contents/icon_pdf.png) no-repeat center right;
                  background-size: 18px auto;
                }
              }
            }
          }
          th {
            background-color: #ecf4f8;
            border-right: 1px solid #d2d8dd;
          }
        }
      }
    }
  }
}

/*
清泰寺について
-------------------------------------*/
#PageSeitaiji.pageIndex {
  #MainImg {
    .innerBasic {
      height: 220px;
      .mainImgBg { background-image: url(../img/contents/seitaiji/mainimg.jpg); }
      h2 {
        line-height: 1.35;
        transform: translateY(2px);
        margin-left: .15em;
        &:after {
          width: 145px;
          height: 106px;
          background-image: url(../img/contents/circle_01.png);
          background-size: 145px auto;
          top: -20px;
          right: -51px;
        }
      }
    }
  }
  #Container {
    background: url(../img/contents/bg_01.jpg) top left;
    background-size: 100px auto;
  }
  #Main {
    .contBox {
      p {
        margin: 0;
        line-height: 1.75;
      }
      img { width: 100%; }
      .imgWrap {
        display: block;
        width: 100%;
      }
    }
    #ContBox01 {
      margin-top: 55px;
      padding-bottom: 74px;
      h3 {
        font-size: 22px;
        color: #07853c;
        line-height: 1.568;
        letter-spacing: .1em;
        text-align: center;
      }
      .contSubBox {
        margin-top: 34px;
        position: relative;
        .imgWrap {
          margin-top: 38px;
          &:before, &:after {
            position: absolute;
            content: '';
          }
          &:before {
            width: 71px;
            height: 93px;
            background: url(../img/contents/illust_07.png) no-repeat center;
            background-size: 71px auto;
            bottom: -38px;
            left: 7px;
          }
          &:after {
            width: 117px;
            height: 217px;
            background: url(../img/contents/illust_06.png) no-repeat top right;
            background-size: 117px auto;
            bottom: -60px;
            right: 2px;
          }
          img {
            position: relative;
            z-index: 2;
          }
        }
      }
    }
    #ContBox02 {
      display: none;
      background-color: #fff;
      padding: 75px 0;
      h3 {
        position: relative;
        display: inline-block;
        font-size: 20px;
        line-height: 1;
        letter-spacing: .15em;
        left: 50%;
        transform: translateX(-50%);
        span {
          position: relative;
          z-index: 1;
        }
        &:after {
          position: absolute;
          content: '';
          width: 71px;
          height: 63px;
          background-image: url(../img/contents/circle_02.png);
          background-size: 71px auto;
          background-position: top left;
          background-repeat: no-repeat;
          top: -27px;
          right: -24px;
        }
      }
      .contSubBox {
        margin-top: 21px;
        .tableWrap {
          margin-top: 22px;
          table {
            width: 100%;
            tr {
              border: 1px solid #d7d7c6;
              &:first-of-type {
                td {
                  padding-bottom: 15px;
                  a {
                    display: inline-block;
                    padding-right: 28px;
                    background: url(../img/contents/icon_pdf.png) no-repeat center right;
                    background-size: 18px auto;
                  }
                }
              }
            }
            th {
              background-color: #f7f5e9;
              border-right: 1px solid #d7d7c6;
            }
            td {
              padding-bottom: 14px;
              small {
                font-size: 14px;
                color: #888;
                line-height: 1.71;
              }
            }
          }
        }
        .imgWrap {
          margin-top: 31px;
          overflow: auto;
          img {
            width: calc( calc(100% - 2.5px) / 2);
            height: auto;
            &:last-of-type { float: right; }
          }
        }
      }
    }
    #ContBox03 {
      background-color: #e4f1f7;
      padding: 56px 0 92px;
      h3 {
        font-size: 20px;
        line-height: 1;
        letter-spacing: .15em;
        padding-left: 15px;
        &:before {
          position: relative;
          display: inline-block;
          content: '';
          width: 22px;
          height: 25px;
          background: url(../img/contents/icon_10.png) no-repeat top left;
          background-size: 22px auto;
          margin-right: 14px;
          bottom: -3px;
        }
      }
      .contSubBox {
        margin-top: 33px;
        .subBoxIn {
          border: 4px solid #cbdfe8;
          border-radius: 8px;
          padding: 26px;
          .textArea {
            margin-top: 24px;
            p { width: 100%; }
            .lists {
              width: 100%;
              margin-top: 9px;
              .list {
                display: block;
                height: auto;
                margin-top: 12px;
                &:first-of-type { margin-top: 0; }
                &:last-of-type span { margin-right: 14px; }
                span {
                  display: inline-block;
                  height: 26px;
                  line-height: 26px;
                  font-size: 14px;
                  color: #fff;
                  letter-spacing: .15em;
                  padding: 0 7px 0 8px;
                  background-color: #7ca8bd;
                  border-radius: 4px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*
お知らせ
-------------------------------------*/
#PageNews {
  #PageTitle {
    h2 {
      &:after {
        width: 76px;
        height: 76px;
        background-image: url(../img/contents/illust_10.png);
        background-size: 76px auto;
        top: -6px;
        right: 47px;
      }
    }
  }
  #Main {
    #ContBox01 {
      position: relative;
      margin-top: 26px;
      .contSubBox {
        &:before {
          position: absolute;
          content: '';
          width: 94px;
          height: 142px;
          background: url(../img/contents/illust_08.png) no-repeat top left;
          background-size: 94px auto;
          top: -39px;
          left: -5px;
        }
      }
    }
  }
}

#PageNews.pageIndex {
  #Main {
    #ContBox01 {
      padding-bottom: 100px;
      .moreBtn { width: 285px; }
    }
  }
}

/*■■■■■下層ページ■■■■■*/
#PageNews.pageEntry {
  #Main {
    #ContBox01 {
      padding-bottom: 89px;
    }
  }
}

/*
よくある質問
-------------------------------------*/
#PageFaq {
  #PageTitle {
    h2 {
      display: inline-block;
      margin-left: -.6em;
      &:after {
        width: 62px;
        height: 50px;
        background-image: url(../img/contents/icon_11.png);
        background-size: 62px auto;
        bottom: 10px;
        right: -55px;
      }
    }
  }
  #ContBox01 {
    position: relative;
    .contSubBox:before {
      position: absolute;
      content: '';
      width: 179px;
      height: 235px;
      background: url(../img/contents/illust_09.png) no-repeat top left;
      background-size: 179px auto;
      top: -27px;
      left: -35px;
      z-index: -1;
    }
  }
  #BlogNavi {
    margin-top: 60px;
    .category {
      h4 {
        line-height: 1;
        letter-spacing: .05em;
        padding-bottom: 13px;
        border-bottom: 1px solid #52a876;
      }
      ul {
        padding: 24px 0;
        li {
          margin-top: 12px;
          &:first-of-type { margin-top: 0; }
          &.current a { color: #888; }
          a {
            display: inline-block;
            font-size: 14px;
            color: #222;
            line-height: 1.5;
            padding-left: 14px;
            background: url(../img/contents/arrow_09.png) no-repeat left center;
            background-size: 6px auto;
          }
        }
      }
    }
  }
}

#PageFaq.pageIndex {
  #Main {
    padding-top: 25px;
    padding-bottom: 100px;
    #ContBox01 {
      .contSubBox {
        &:before { display: none; }
        .catBtnWrap {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .catBtn {
            width: 100%;
            height: 70px;
            margin-top: 10px;
            &:first-of-type {
              margin-top: 0;
              img { width: 46px; left: 9px; }
            }
            &:nth-of-type(2) img { width: 35px; left: 15px; }
            &:nth-of-type(3) img { width: 32px; left: 16px; }
            &:nth-of-type(4) img { width: 27px; left: 19px; }
            &:nth-of-type(5) img { width: 33px; left: 16px; }
            &:last-of-type img { width: 38px; left: 13px; }
            a {
              display: block;
              width: 100%;
              height: 100%;
              line-height: 70px;
              border-radius: 8px;
              background-size: 22px auto;
              background-repeat: no-repeat;
              background-position: top 28px right 15px;
              position: relative;
              img {
                position: absolute;
                top: 50%;
                transform: translateY(-55%);
              }
              span {
                margin-left: 63px;
                font-size: 18px;
                line-height: 1;
              }
            }
          }
          .greenBtn a {
            background-color: #e8f6ee;
            background-image: url(../img/contents/arrow_04.png);
            color: #46ae72;
          }
          .orangeBtn a {
            background-color: #f6eee3;
            background-image: url(../img/contents/arrow_05.png);
            color: #e98000
          }
          .blueBtn a {
            background-color: #e2f1f8;
            background-image: url(../img/contents/arrow_06.png);
            color: #2188b4;
          }
          .grayBtn a {
            background-color: #f0efeb;
            background-image: url(../img/contents/arrow_07.png);
            color: #888888;
          }
        }
      }
    }
  }
}

/*■■■■■下層ページ■■■■■*/
#PageFaq.pageCategory {
  #Main {
    #ContBox01 {
      padding-top: 13px;
      padding-bottom: 39px;
      #CatName {
        width: 100%;
        padding: 0 15px;
        h3 {
          height: 47px;
          font-size: 20px;
          color: #46ae72;
          line-height: 50px;
          letter-spacing: .075em;
          display: block;
          img {
            display: inline-block;
            width: 52px;
            margin-right: 10px;
            position: relative;
          }
          &.cemetery img { top: -13px; }
          &.grave img { width: 30px; top: -10px; }
          &.liturgy img { width: 30px; top: -11px; }
          &.stupa img { width: 22px; top: -12px; }
          &.clean img { width: 30px; top: -7px; }
          &.other img { width: 38px; top: -8px; }
        }
      }
      .contSubBox {
        margin-top: 10px;
        .moreBtn {
          width: 285px;
          margin-top: 30px;
        }
      }
    }
  }
}

#PageFaq.pageEntry {
  #Main {
    #ContBox01 {
      padding-top: 24px;
      padding-bottom: 33px;
      .contSubBox {
        .entryWrap {
          .entryIn {
            padding-top: 56px;
            padding-bottom: 54px;
            p { margin-top: 28px; }
          }
          .entryTag {
            display: inline-block;
            font-size: 14px;
            color: #fff;
            letter-spacing: .15em;
            line-height: 1;
            background-color: #46ae72;
            border-radius: 4px;
            padding: 6px 5px 6px 7px;
            margin-right: 5px;
            margin-bottom: 5px;
            &:last-of-type { margin: 0 0 24px;}
          }
          .entryTitle { padding-bottom: 23px; }
        }
        .btnWrap {
          width: 100%;
          margin-top: 44px;
          a:nth-of-type(2) {
            position: relative;
            top: 6px;
          }
        }
        #BlogNavi {
          h4 { padding-bottom: 14px; }
        }
      }
    }
  }
}

/*
お問い合わせ
-------------------------------------*/
#PageContact, #PageToba, #PageCleaning, #PageTenrei {
  #MainImg  {
    #PageTitle h2 {
      display: inline-block;
      margin-left: .2em;
    }
  }
  #Main {
    .contBox {
      line-height: 1.75;
      p { line-height: 1.75; }
      .noticeList {
        margin-top: 22px;
        .notice {
          padding-left: 16px;
          position: relative;
          &:before {
            position: absolute;
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #46ae72;
            top: 9px;
            left: 0;
          }
        }
      }
    }
  }
}
#PageToba, #PageCleaning, #PageTenrei {
  #Main {
    #ContBox01,
    #ContBox02 .noticeWrap {
      h3 {
        font-size: 18px;
        line-height: 1;
        &:before {
          display: inline-block;
          content: '';
          width: 34px;
          height: 34px;
          background: url(../img/contents/icon_12.png) no-repeat center;
          background-size: 34px;
          margin-right: 10px;
          transform: translateY(10px);
        }
      }
      .noticeList {
        .notice a {
          display: inline-block;
          padding-right: 19px;
          background: url(../img/contents/icon_link_bl.png) no-repeat right top 6px;
          background-size: 14px auto;
          margin-right: 7px;
        }
      }
    }
  }
  &.pageIndex {
    .confirm_display { display: none; }
  }
  &.pageConfirm {
    .index_display { display: none; }
    #Main #ContBox01 .contSubBox01 {
      p.error_messe { margin-bottom: 0; }
      .btnArea {
        text-align: center;
        margin-top: 30px;
        input {
          font-size: 14px;
          color: #2188b4;
        }
      }
    }
  }
}

#PageContact.pageIndex {
  #Main {
    margin-top: 7px;
    #ContBox01 {
      padding-bottom: 75px;
      .contSubBox01 {
        p {
          margin: 0;
        }
        .privacyBtn {
          display: block;
          margin: 9px auto 0;
          width: 180px;
          height: 40px;
          line-height: 40px;
          background-color: #efeeea;
          border-radius: 8px;
          text-align: center;
          span {
            font-size: 14px;
            color: #888;
            line-height: 1;
            &:before {
              display: inline-block;
              content: '';
              width: 10px;
              height: 11px;
              background: url(../img/contents/arrow_08.png) no-repeat center;
              background-size: 10px;
              margin-right: 6px;
            }
          }
        }
        h3 {
          font-size: 18px;
          line-height: 1;
          margin-top: 49px;
          &:before {
            display: inline-block;
            content: '';
            width: 34px;
            height: 34px;
            background: url(../img/contents/icon_12.png) no-repeat center;
            background-size: 34px;
            margin-right: 10px;
            transform: translateY(10px);
          }
        }
      }
      .contSubBox02 {
        margin-top: 24px;
        .click {
          width: 100%;
          height: 50px;
          line-height: 50px;
          background-color: #46ae72;
          border-radius: 8px;
          text-align: center;
          transition: all .2s ease;
          span {
            font-size: 15px;
            color: #fff;
            line-height: 1;
            letter-spacing: .2em;
            padding-right: 24px;
            background: url(../img/contents/icon_open_wh.png) no-repeat top 1px right;
            background-size: 15px auto;
          }
          &.active {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            span { background-image: url(../img/contents/icon_close_wh.png); }
          }
        }
        .showBox {
          table {
            width: 100%;
            th, td {
              border: 1px solid #d7d7c6;
              padding: 13px 18px 13px 14px
            }
            th {
              width: 54%;
              background-color: #f7f5e9;
              font-weight: normal;
              small {
                font-size: 14px;
                color: #888;
                margin-left: 5px;
              }
            }
            td {
              vertical-align: middle;
              .shopLink {
                padding-right: 22px;
                background: url(../img/contents/icon_link_bl.png) no-repeat right center;
                background-size: 14px auto;
                list-style: 1.75;
              }
            }
          }
        }
      }
    }
    #ContBox02 {
      background: url(../img/contents/bg_03.jpg) top left;
      background-size: 75px auto;
      padding: 45px 0;
      .contSubBox {
        text-align: center;
        h3 {
          font-size: 20px;
          line-height: 1;
          letter-spacing: .15em;
        }
        & > div {
          background-color: #fff;
          border-radius: 8px;
          padding: 45px 30px 36px;
        }
        .telBtn {
          .telWrap {
            margin-top: 28px;
            border-top: 1px solid #46ae72;
            border-bottom: 1px solid #46ae72;
            padding: 29px 0;
            a {
              display: block;
              width: auto;
              height: auto;
              color: #222;
            }
            span {
              display: block;
              font-size: 30px;
              line-height: 1;
              letter-spacing: .1em;
              padding-left: 42px;
              position: relative;
              &:before {
                position: absolute;
                content: '';
                width: 28px;
                height: 26px;
                background: url(../img/contents/icon_tel.png) no-repeat center;
                background-size: 28px auto;
                top: 2px;
                left: 13px;
              }
            }
            small {
              display: block;
              font-size: 14px;
              color: #888;
              line-height: 1;
              margin-top: 11px;
            }
          }
        }
        .contactBtns {
          margin-top: 19px;
          &:after {
            display: block;
            content: '';
            clear: both;
          }
          .contactBtn {
            width: 49%;
            height: 50px;
            p {
              font-size: 16px;
              line-height: 1.25;
              position: relative;
              top: 50%;
              transform: translateY(-48%);
            }
            &.tenrei {
              width: 100%;
              position: relative;
              margin-top: 29px;
              p {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -44%);
                padding-left: 39px;
                &:before {
                  position: absolute;
                  top: -6px;
                  left: 0;
                }
              }
            }
            &.toba { float: left; }
            &.cleaning { float: right; }
          }
        }
      }
    }
    #ContBox03 {
      .contSubBox {
        padding-top: 73px;
        padding-bottom: 86px;
        .boxTitle {
          text-align: center;
          h3 {
            display: inline-block;
            font-size: 20px;
            line-height: 1;
            letter-spacing: .15em;
            position: relative;
            margin-left: -4px;
            span { position: relative; z-index: 2;}
            &:after {
              position: absolute;
              content: '';
              width: 71px;
              height: 63px;
              background: url(../img/contents/circle_06.png) no-repeat center;
              background-size: 71px auto;
              top: -26px;
              right: -48px;
            }
          }
        }
        p.foreword {
          font-size: 16px;
          line-height: 1.75;
          margin-top: 57px;
          margin-bottom: 54px;
        }
        .textBox {
          margin-top: 40px;
          h4 {
            font-size: 16px;
            line-height: 1;
          }
          p {
            font-size: 14px;
            line-height: 1.75;
            letter-spacing: .025em;
            margin: 0;
            margin-top: 13px;
          }
          ul {
            li {
              font-size: 14px;
              line-height: 1.75;
              &:before {
                display: inline-block;
                content: '・';
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}

/*■■■■■お塔婆お申し込み■■■■■*/
#PageToba.pageIndex {
  #TopicPath ol {
    li { span.confirm_display { display: none; } }
  }
  #Main {
    padding-top: 6px;
    #ContBox01 {
      .contSubBox {
        h3 { margin-top: 27px; }
      }
    }
    #ContBox02 {
      .formWrap {
        .formIn {
          tr {
            &:last-of-type td textarea { height: 275px; }
            &:nth-of-type(16) {
              td {
                input {
                  width: 90px;
                  margin-right: 10px;
                }
              }
            }
            &:nth-of-type(3), &:nth-of-type(4),
            &:nth-of-type(5), &:nth-of-type(17),
            &:nth-of-type(18) { padding-bottom: 12px; }
            &:nth-of-type(16) { td span { width: auto; } }
            &:nth-of-type(19) {
              padding-bottom: 8px !important;
              .first label:first-of-type {
                &:after {
                  display: inline-block;
                  content: '※回忌の場合は備考に何回忌かを記入して下さい。';
                  font-size: 14px;
                  color: #888;
                }
              }
              span:nth-of-type(3),
              span:nth-of-type(5) {
                margin-right: calc(100% - 256px);
              }
            }
            &:last-of-type td { span.message { width: 100%; }}
          }
        }
        //wp contact form
        span.date { width: 100%; }
        span.wpcf7-list-item {
          label { margin-right: 18px; }
          &.first {
            display: block;
            margin: 0;
            label { margin: 0; }
          }
          &:nth-of-type(2),&:nth-of-type(4) { margin-left: 0; }
          &:last-of-type { margin-left: 1.85em; }
        }
        input.wpcf7c-conf,
        textarea.wpcf7c-conf {
          width: 100%;
          padding-left: 0;
          padding-right: 0;
        }
        textarea.wpcf7-textarea { width: 100% !important; }
        .ajax-loader {
          display: block;
          margin: 0 auto;
        }
        .sendBtn {
          margin-top: 22px;
          .wpcf7-confirm { margin-bottom: 110px; }
          .wpcf7-submit { margin-top: 23px; }
          .wpcf7-back {
            margin-top: -40px;
            margin-bottom: 70px;
            font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
            font-weight: 500;
          }
        }
      }
    }
  }
}
#PageToba.pageConfirm {
  #TopicPath {
    span.confirm_display { display: inline-block; }
  }
  #Main {
    padding-top: 6px;
    padding-bottom: 59px;
    #ContBox01 {
      .contSubBox01 {
        p { line-height: 1.75; }
      }
    }
    #ContBox02 {
      .contSubBox {
        .formWrap {
          margin-top: 54px;
          .formIn {
            tr {
              padding: 19px 0 13px;
            }
            td {
              line-height: 1.625;
              margin-top: 10px;
              input.wpcf7c-conf { padding: 0;}
              span.toba { width: 50px; }
              span.is_checked {
                margin: 0;
                label {
                  padding: 0;
                  margin: 0;
                }
              }
            }
          }
          .sendBtn {
            margin-top: 45px;
          }
          .textBack {
            display: inline-block;
            font-size: 14px;
            line-height: 1;
            margin-top: 31px;
          }
        }
      }
    }
  }
}
#PageToba.pageThanks {
  #Main {
    padding-top: 7px;
    padding-bottom: 95px;
  }
}

/*■■■■■掃除代行お申し込み■■■■■*/
#PageCleaning {
  #MainImg {
    #PageTitle {
      padding-top: 39px;
      h2 {
        line-height: 1.5;
        margin-left: .3em;
      }
    }
  }
}
#PageCleaning.pageIndex {
  #TopicPath ol {
    li { span.confirm_display { display: none; } }
  }
  #Main {
    padding-bottom: 78px;
    #ContBox01 {
      .contSubBox {
        h3 { margin-top: 27px; }
      }
    }
    #ContBox02 {
      margin-top: 39px;
      .click {
        width: 100%;
        height: 70px;
        background-color: #f7f5e9;
        background: url(../img/contents/icon_open_bl.png) no-repeat center right 15px;
        background-size: 20px auto;
        padding: 0 15px;
        p {
          display: inline-block;
          margin: 0;
          position: relative;
          top: 46%;
          transform: translateY(-50%);
          font-size: 18px;
          color: #2188b4;
        }
        &.active { background-image: url(../img/contents/icon_close.png); }
      }
      .showBox {
        margin-top: 30px;
        .textBox {
          margin-top: 30px;
          .price {
            width: 100%;
            caption {
              background-color: #46ae72;
              color: #fff;
              line-height: 2;
              border: 1px solid #d7d7c6;
              border-bottom-width: 0;
            }
            th {
              width: 33.3%;
              font-size: 14px;
              font-weight: normal;
              background-color: #f7f5e9;
              border: 1px solid #d7d7c6;
              padding: 13px 13px 12px;
              vertical-align: middle;
            }
            td {
              width: 33.3%;
              font-size: 14px;
              line-height: 21px;
              text-align: center;
              vertical-align: middle;
              border: 1px solid #d7d7c6;
              padding: 7px 0 6px;
            }
          }
          .subPrice {
            caption {
              font-size: 16px;
              line-height: 1.625;
              padding: 5px 0;
            }
            tr:last-of-type {
              th,td { padding: 7px 13px 6px; }
            }
          }
          ul {
            margin-top: 10px;
            li{
              font-size: 14px;
              color: #888;
              line-height: 1.7;
            }
          }
        }
        .scrollBox {
          overflow-x: scroll;
          margin-top: 0;
          .mainPrice {
            width: 500px !important;
            caption {
              text-align: left;
              padding-left: 147px;
              height: 50px;
              line-height: 50px;
            }
            tr:first-of-type {
              td { background-color: #fcfbf5; }
            }
            th, td { width: 20%; }
          }
          .mcsB_scrollTools { width: calc(100% - 15px); }
        }
        span.note {
          font-size: 14px;
          line-height: 1.7;
          margin-bottom: 2px;
        }
      }
    }
    #ContBox03 {
      .formWrap {
        margin-top: 29px;
        .formIn {
          tr:nth-of-type(3) {
            padding-bottom: 16px;
            td label {
              display: inline-block;
              padding-top: 8px;
              padding-left: 50px;
              div {
                position: absolute;
                top: 1px;
                left: 0;
              }
            }
          }
          tr:nth-of-type(4) {
            padding-bottom: 12px;
            small { margin-top: 2px; }
          }
          tr:nth-of-type(5) {
            td {
              span {
                position: relative;
                top: -3px;
              }
              span.zipcode input {
                width: 145px;
                margin-left: 7px;
                margin-bottom: 10px;
              }
              span.zipcode input.wpcf7c-conf { margin: 0; padding-left: 0; }
              span.address input.wpcf7c-conf { padding-top: 0; padding-left: 0; }
            }
          }
          tr:nth-of-type(9) {
            td {
              p {
                margin: 0 0 10px;
                text-align: left;
                &.sub {
                  padding-left: 50px;
                  input {
                    width: 80px;
                    margin: 0 6px;
                    &:last-of-type { margin-top: 8px; }
                  }
                  span:nth-of-type(3),
                  span:nth-of-type(4) {
                    position: relative;
                    top: 6px;
                  }
                }
                &:last-of-type { margin: 0; }
              }
              .service {
                margin-bottom: 10px;
                &:last-of-type { margin-bottom: 0; }
              }
              .sub {
                padding-left: 50px;
                display: block;
                input.hanaNum {
                  width: 80px;
                  margin: 0 6px;
                }
                .hana02 {
                  padding-left: 0;
                  margin: 10px 0;
                }
              }
              label { padding: 0; margin: 0; }
              .checker {
                width: 40px;
                height: 40px;
                margin-right: 10px;
                span {
                  width: inherit;
                  height: inherit;
                  background-color: #eee;
                  border-radius: 4px;
                  position: relative;
                  input { width: 100%; height: 100%; padding: 0; }
                  &.checked:before {
                    position: absolute;
                    content: '';
                    width: 24px;
                    height: 20px;
                    background: url(../img/contents/icon_check.png) no-repeat top left;
                    background-size: 24px auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
              }
              br:nth-of-type(4) { display: none; }
            }
          }
          tr:nth-of-type(10) {
            align-items: start;
            td {
              input { margin-bottom: 5px; }
              span.index_display {
                display: inline-block;
                margin-top: 0;
              }
            }
          }
        }
        //wp contact form 7
        span.wpcf7-form-control-wrap {
          &.zipcode, &.hana01, &.hana02 { width: auto; }
          .wpcf7-radio {
            span.wpcf7-list-item {
              display: block;
              margin-left: 0;
              height: 40px;
              &.first { margin-bottom: 5px; }
            }
          }
          input.wpcf7c-conf,
          textarea.wpcf7c-conf {
            padding-left: 0;
            padding-right: 0;
          }
          textarea.wpcf7-textarea { width: 100% !important; }
        }
        .sendBtn {
          margin-top: 45px;
          .textBack {
            display: inline-block;
            font-size: 14px;
            line-height: 1;
            margin-top: 31px;
          }
        }
      }
    }
  }
}
#PageCleaning.pageConfirm {
  #TopicPath {
    span.confirm_display { display: inline-block; }
  }
  #Main {
    padding-bottom: 75px;
    #ContBox03 {
      .contSubBox {
        .formWrap {
          margin-top: 53px;
          .formIn {
            tr {
              padding-bottom: 11px;
            }
            td {
            //   margin-top: 7px;
              input, textarea { padding: 0; }
              p {
                margin: 0;
                &.sub { margin: 0; }
              }
              span.is_checked {
                label {
                  margin: 0;
                  padding: 0;
                }
              }
              span.hana01, span.hana02 {
                width: 50px;
                margin-left: 10px;
              }
              span.sub {
                display: block;
                br.sp_display { display: block; }
              }
              span#js_sub { display: none; }
            }
            tr:nth-of-type(5) { align-items: start; }
            tr:nth-of-type(8) { display: none; }
            tr:nth-of-type(9) {
              padding-bottom: 16px;
              align-items: start;
              td {
                p { line-height: 1.65; }
                span.sub { padding-left: 15px; }
                br { display: none; }
              }
            }
            tr:last-of-type { align-items: start; }
          }
          //wp contact form 7
          span.wpcf7-form-control-wrap {
            &.zipcode { width: auto; }
            .wpcf7-radio, .wpcf7-checkbox {
              .wpcf7-list-item {
                display: block;
                margin: 0;
              }
            }
            &.hana01, &.hana02 {
              .wpcf7c-conf.hanaNum { width: inherit; }
            }
            &.not_checked { display: none; }
          }
          .sendBtn { margin-top: 45px; }
          .textBack {
            display: inline-block;
            font-size: 14px;
            line-height: 1;
            margin-top: 1px;
          }
        }
      }
    }
  }
}
#PageCleaning.pageThanks {
  #Main {
    padding-bottom: 99px;
    #ContBox01 {
      .contSubBox {
        .topBtn { margin-top: 45px; }
      }
    }
  }
}

/*■■■■■典礼ご予約■■■■■*/
#PageTenrei {
  #MainImg {
    #PageTitle {
      padding: 46px 0 50px;
    }
  }
  .formWrap {
    .checker {
      width: 40px;
      height: 40px;
      display: inline-block;
      margin-right: 8px;
      position: relative;
      top: -1px;
      span {
        display: inline-block;
        width: inherit;
        height: inherit;
        background-color: #eee;
        border-radius: 50%;
        position: relative;
        &.checked:before {
          position: absolute;
          content: '';
          width: 12px;
          height: 12px;
          background-color: #46ae72;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        input { width: inherit; height: inherit; }
      }
    }
  }
}
#PageTenrei.pageIndex {
  #TopicPath ol {
    li { span.confirm_display { display: none; } }
  }
  #Main {
    #ContBox01 {
      .contSubBox {
        p { margin-bottom: 29px; }
      }
    }
    #ContBox02 {
      padding-bottom: 77px;
      .contSubBox {
        .formWrap {
          span {
            &.wpcf7-list-item { margin: 0; }
          }
          .wpcf7-response-output { font-size: 18px; }
        }
        .noticeWrap {
          margin-bottom: 41px;
          .noticeList {
            .notice:nth-of-type(2) {
              font-weight: bold;
            }
          }
        }
        .formText {
          h3 {
            font-size: 22px;
            letter-spacing: 0.05em;
          }
        }
        .formText01 {
          margin-bottom: 20px;
        }
        .formText02 {
          margin-top: 38px;
          margin-bottom: 23px;
          h3 {
            margin-bottom: 16px;
          }
        }
        .formIn {
          max-width: 100%;
          td {
            p {
              margin-bottom: 6px;
              &:nth-of-type(2) { margin-bottom: 0; }
              .exText {
                display: block;
                padding-left: 3.5em;
                position: relative;
                &:before {
                  position: absolute;
                  content: '例）';
                  left: 2em;
                }
              }
            }
            input.js-datepicker {
              height: 40px;
              background-image: url(../img/contents/arrow_12.png);
              background-repeat: no-repeat;
              background-position: right 15px center;
              background-size: 7px auto;
              &:hover { cursor: context-menu; }
            }
            label {
              width: 100%;
              margin-right: 0;
              margin-bottom: 17px;
              & + input[type="text"] {
                width: calc(100% - 50px);
                margin-left: 50px;
              }
            }
            .selector {
              width: 100% !important;
              background: #eee url(../img/contents/arrow_12.png) no-repeat right 15px center;
              background-size: 7px auto;
              border-radius: 4px;
              margin-top: 10px;
              span, select, option {
                width: 100% !important;
                line-height: 1.5;
                padding: 9px 15px 7px;
                overflow: hidden;
              }
              span {
                color: #888;
                padding-right: 25px;
                max-width: calc(100vw - 30px);
              }
              select {
                overflow-x: hidden;
                white-space: normal;
              }
            }
          }
        }
        .formIn01 {
          tr {
            &:nth-of-type(3) {
              padding-bottom: 12px;
            }
            &:nth-of-type(4) {
              padding-bottom: 9px;
            }
          }
        }
        .formIn02 {
          tr {
            padding-bottom: 11px;
            &:nth-of-type(1) td,
            &:nth-of-type(14) td {
              textarea { height: 115px; }
            }
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6) {
              padding-bottom: 21px;
              td .selector > span.timeText {
                position: relative;
                &:before {
                  position: absolute;
                  content: '時刻をご選択ください。';
                  width: 200px;
                  background-color: #eee;
                  color: #888;
                  top: 50%;
                  left: 15px;
                  transform: translateY(-45%);
                  pointer-events: none;
                }
              }
            }
            &:nth-of-type(7),
            &:nth-of-type(13),
            &:nth-of-type(15) {
              padding-bottom: 9px;
            }
            &:nth-of-type(7) {
              td {
                p { margin-bottom: 10px; }
                label {
                  padding: 0;
                  margin-bottom: 10px;
                  .checker { top: -3px; }
                }
                .wpcf7-list-item {
                  &:first-of-type label { margin-bottom: 12px; }
                  &:last-of-type label { margin-bottom: 0; }
                }
              }
            }
            &:nth-of-type(7) {
              .checker span {
                border-radius: 4px;
                &.checked:before {
                  width: 24px;
                  height: 20px;
                  background: url(../img/contents/icon_check.png) no-repeat top left;
                  background-size: 24px auto;
                  border-radius: 0;
                }
              }
            }
            &:nth-of-type(8)  {
              padding-bottom: 20px;
              td {
                span.wpcf7-list-item {
                  width: auto;
                  margin-right: 20px;
                  &:last-of-type {
                    margin: 0;
                    label { margin-bottom: 7px; }
                  }
                }
                label {
                  width: auto;
                  margin-right: 20px;
                  &:last-of-type {
                    margin: 0 0 7px 0;
                  }
                }
                input[type="text"] {
                  width: calc(100% - 50px);
                  margin-left: 50px;
                }
              }
            }
            &:nth-of-type(9) {
              padding-bottom: 24px;
              td {
                p { margin-bottom: -4px; }
                span.timeText {
                position: relative;
                &:before {
                  position: absolute;
                  content: '選択してください。';
                  width: 200px;
                  background-color: #eee;
                  color: #888;
                  top: 50%;
                  left: 15px;
                  transform: translateY(-45%);
                  pointer-events: none;
                }
              }
              }
            }
            &:nth-of-type(10) {
              padding-bottom: 8px;
              td p { margin-bottom: 12px; }
            }
            &:nth-of-type(11),
            &:nth-of-type(12) {
              padding-bottom: 18px;
              td label:first-of-type { margin-bottom: 8px; }
            }
            &:nth-of-type(13),
            &:nth-of-type(15),
            &:nth-of-type(16),
            &:nth-of-type(17) {
              span.wpcf7-list-item { width: 100%; }
            }
            &:nth-of-type(17) {
              padding-bottom: 7px;
              td label:first-of-type { margin-bottom: 10px; }
            }
          }
          td {
            margin-top: 7px;
          }
        }
        .btnArea {
          margin-top: 17px;
          input {
            width: 285px;
            height: 70px;
            background-color: #46ae72;
            font-size: 18px;
            color: #fff;
            line-height: 71px;
            letter-spacing: 0.2em;
            padding-left: 0.2em;
            border-radius: 8px;
            transform: translateX(-5px);
          }
        }
      }
    }
  }
}
#PageTenrei.pageConfirm {
  #TopicPath {
    span.confirm_display { display: inline-block; }
  }
  #Main {
    #ContBox02 {
      padding-bottom: 76px;
      .contSubBox {
        .formWrap { margin-top: 46px; }
        .formText {
          h3 {
            font-size: 22px;
            letter-spacing: 0.05em;
          }
        }
        .formText01 {
          margin-bottom: 20px;
        }
        .formText02 {
          margin-top: 37px;
          margin-bottom: 20px;
          h3 {
            margin-bottom: 16px;
          }
        }
        .formIn {
          tr { padding-bottom: 25px; }
          th {
            position: relative;
            padding-left: 54px;
            &:before {
              position: absolute;
              left: 0;
            }
            p { margin-bottom: 0; }
          }
          td {
            // margin-top: 15px;
            input, textarea { padding: 0; }
            span {
              &.wpcf7-list-item {
                margin: 0;
                label {
                  padding: 0;
                  margin: 0;
                }
              }
              &.date-01, &.date-02 ,&.date-03, &.date-04, &.date-05 {
                width: 130px;
              }
              &.time01, &.time02, &.time03, &.time04, &.time05 {
                width: auto;
              }
            }
            select, option {
              width: 100% !important;
            }
            p {
              width: 100%;
              margin-bottom: 0;
            }
          }
        }
        .formIn02 {
          tr {
            &:nth-of-type(1) { padding-bottom: 22px; }
            &:nth-of-type(2),
            &:nth-of-type(4),
            &:nth-of-type(5) {
              padding-bottom: 24px;
            }
            &:nth-of-type(7) td {
              .is_checked {
                &:after {
                  display: inline-block;
                  content: '、';
                }
                &:last-of-type:after { display: none; }
              }
            }
            &:nth-of-type(10) td {
              position: relative;
              span {
                width: auto;
                &:after {
                  position: absolute;
                  content: '名';
                  margin-left: 0;
                  transform: translateY(0);
                  right: 0;
                }
              }
            }
            &:nth-of-type(14),
            &:nth-of-type(18) {
              padding-bottom: 18px;
            }
          }
        }
        .btnArea {
          position: relative;
          margin-top: 45px;
          .ajax-loader {
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            margin: 0;
          }
          br { display: none; }
        }
      }
    }
  }
}
#PageTenrei.pageThanks {
  #Main {
    #ContBox01 {
      padding-bottom: 94px;
    }
    .contSubBox {
      & > span {
        display: block;
        text-align: justify;
      }
      .telWrap {
        margin-top: 38px;
      }
      .topBtn {
        width: 255px;
        line-height: 54px;
      }
    }
  }
}

/*
永代供養墓「大地」
-------------------------------------*/
#PageDaichi.pageIndex {
  #MainImg {
    .innerBasic {
      .mainImgBg {
        background-image: url(../img/contents/daichi/mainimg.jpg);
      }
      h2 {
        position: absolute;
        background-image: url(../img/contents/daichi/logo.png);
        background-size: cover;
      }
    }
  }
  #Main {
    #ContBox01 {
      margin-top: 0;
      background: linear-gradient(35deg, #d0c5a9, #ffffff);
      position: relative;
      &:before {
        position: absolute;
        content: '';
        width: 50%;
        height: 43px;
        background: linear-gradient(to left, rgba(135,124,109,0.4) 0%,rgba(135,124,109,0.17) 58%, transparent 100%) left bottom / 100% 3px no-repeat,
                    linear-gradient(to left, rgba(135,124,109,1) 0%,rgba(135,124,109,0.27) 58%, transparent 100%) left top / 100% 40px no-repeat;
        top: 0;
        right: 0;
      }
      &:after {
        position: absolute;
        content: '';
        width: 50%;
        height: 43px;
        background: linear-gradient(to right, rgba(135,124,109,0.4) 0%,rgba(135,124,109,0.17) 58%, transparent 100%) left top / 100% 3px no-repeat,
                    linear-gradient(to right, rgba(135,124,109,1) 0%,rgba(135,124,109,0.27) 58%, transparent 100%) left bottom / 100% 40px no-repeat;
        left: 0;
        bottom: 0;
      }
      #TopicPath {
        margin-bottom: 56px;
      }
      .foreword {
        padding: 0 15px;
        line-height: 28px;
        letter-spacing: 0.05em;
        margin-bottom: 32px;
      }
      h3 {
        color: #6c3e04;
        margin-bottom: -1px;
      }
    }
    #ContBox02 {
      padding-bottom: 92px;
      h3 {
        &:after {
          background-image: url(../img/contents/circle_07.png);
        }
      }
      .contSubBox {
        margin-top: 19px;
        p {
          margin-bottom: 23px;
        }
        table {
          tr {
            border: 1px solid #d0ccc3;
            &:nth-of-type(2) td {
              padding-bottom: 13px;
            }
          }
          th {
            border-right: 1px solid #d0ccc3;
            background-color: #f1eee6;
          }
          td {
            padding-bottom: 15px;
            a[href$="pdf"] {
              display: inline-block;
              padding-right: 27px;
              background: url(../img/contents/daichi/icon_pdf.png) no-repeat right top 1px;
              background-size: 18px auto;
            }
            small {
              display: inline-block;
              font-size: 14px;
              color: #888;
              line-height: 24px;
              letter-spacing: 0.025em;
              transform: translateY(-2px);
            }
          }
        }
      }
    }
  }
}


/*#################### PC ####################*/
@media print,
screen and (min-width: 768px) {
  /*
  共通
  -------------------------------------*/
  .linkBoxWrap {
    .linkBoxIn {
      max-width: 1000px;
      margin: 0 auto;
      justify-content: space-between;
      .linkBox {
        width: 33.3%;
        height: 488px;
        .boxImg {
          height: 438px;
          overflow: hidden;
          .img {
            min-width: auto;
            background-position: center;
            transform: scale(1);
            transition: transform .3s ease;
            left: 0;
          }
          .boxLogo {
            width: 160px;
            height: 160px;
            top: auto;
            bottom: 25px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        &:first-of-type {
          .boxImg {
            .img { background-image: url(../img/contents/top/img_01.jpg); }
            span img { width: 90px; }
          }
        }
        &:nth-of-type(2) {
          width: 33.2%;
          .boxImg {
            .img { background-image: url(../img/contents/top/img_02.jpg); }
            span img { width: 105px; }
          }
        }
        &:last-of-type {
          margin-bottom: 0;
          .boxImg {
            .img { background-image: url(../img/contents/top/img_03.jpg); }
            span img { width: 81px; }
          }
        }
        &:hover {
          .boxImg .img { transform: scale(1.1); }
        }
      }
    }
    .imgBtn {
      margin-top: 90px;
      height: 420px;
      overflow: hidden;
      .btnBg {
        min-width: 1280px;
        transform: translateX(-50%) scale(1);
        transition: transform .3s ease;
      }
      .pageBtn {
        right: 50%;
        bottom: 50%;
        transform: translate(50%, 50%);
        width: 360px;
        height: 70px;
        line-height: 70px;
        background-position: top 31px right 15px;
        span {
          font-size: 20px;
          margin: 0;
        }
      }
      &:hover {
        .btnBg { transform: translateX(-50%) scale(1.1); }
        .pageBtn { opacity: 1; }
      }
    }
  }
  .accessBoxWrap {
    .accessBoxIn {
      width: 1000px;
      letter-spacing: .15em;
      .boxTitle {
        h3 {
          width: 214px;
          height: 84px;
          font-size: 24px;
          line-height: 104px;
          background-size: 94px auto;
        }
      }
      .boxContent {
        margin-top: 39px;
        &:before {
          width: 204px;
          height: 221px;
          background-size: 204px auto;
          top: -81px;
          left: -57px;
        }
        .googleMap {
          height: 340px;
          border: 8px solid #fff;
        }
        .boxTextWrap {
          .boxText {
            display: flex;
            .textLeft {
              width: calc(100% - 550px);
              padding-right: 18px;
              .wayLists {
                margin-top: 15px;
                .wayList {
                  letter-spacing: 0;
                  line-height: 2;
                  &:before {
                    width: 8px;
                    height: 8px;
                    top: 11px;
                  }
                }
              }
            }
            .boxSubText {
              width: 550px;
              margin-top: -16px;
              p {
                letter-spacing: .05em;
              }
            }
          }
        }
      }
    }
    .noticeBox {
      margin-top: 121px;
      padding: 62px 0 53px;
      .noticeBoxIn {
        width: 1000px;
        margin: 0 auto;
        .noticeLists {
          .noticeList { text-align: justify; }
        }
      }
    }
  }
  .tableWrap {
    table {
      width: 100%;
      th { width: 20%; }
      td {
        line-height: 2;
        padding: 19px 28px 18px;
        small { line-height: 2; }
      }
    }
  }
  .listWrap {
    background-color: #fff;
    .list {
      margin-top: 30px;
      .listCont {
        background-position: center right 25px;
        padding: 51px 116px 46px 56px;
        &:hover {
          text-decoration: none;
          opacity: .7;
          .listTitle { text-decoration: underline; }
        }
      }
      .listTitle {
        line-height: 2;
        margin-top: -1px;
      }
      p {
        margin-top: -2px;
        line-height: 2;
      }
    }
  }
  .entryWrap {
    .entryIn {
      padding: 51px 56px 76px;
      p {
        line-height: 2;
        margin-top: 32px;
      }
    }
    .entryTime { margin-bottom: 16px; }
    .entryTitle {
      font-size: 28px;
      line-height: 1.9;
      padding-bottom: 16px;
      margin-bottom: 52px;
    }
  }
  .pageEntry .btnWrap {
    margin: 30px auto 0;
    width: 1000px;
    height: 50px;
    line-height: 50px;
    .button {
      width: 104px;
      height: 50px;
      line-height: 50px;
      &:hover {
        opacity: .7;
        a { text-decoration: none; }
      }
    }
    .prev {
      span {
        left: -8px;
        background-position: left top 5px;
      }
    }
    .next {
      span {
        line-height: 52px;
        right: -9px;
      }
    }
  }
  .formWrap {
    .formIn {
      tr {
        display: flex;
        align-items: center;
        padding: 20px 0 19px !important;
      }
      th {
        width: 297px;
        &:before { margin-right: 19px; }
        small {
          display: block;
          margin-left: 0;
          padding-left: 64px;
        }
      }
      td {
        width: 703px;
        margin-top: 0;
        input { width: 330px; }
        small {
          margin-top: 0;
          margin-left: 14px;
        }
        label {
          &:first-of-type { margin-bottom: 18px; }
          &:nth-of-type(4) { margin-right: 21px; }
        }
      }
    }
    //wp contact form 7
    span.wpcf7-form-control-wrap {
      &:after {
        margin-top: 0;
        margin-left: 15px;
        transform: translateY(1.5px);
      }
    }
    .sendBtn {
      margin-top: 60px;
      position: relative;
      .button {
        width: 480px;
        height: 70px;
        line-height: 72px;
        background-color: #46ae72;
        font-size: 18px;
        color: #fff;
        letter-spacing: .2em;
        border-radius: 8px;
        &:hover { opacity: .7; }
      }
      .textBack {
        position: absolute;
        margin: 0;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        &:hover { text-decoration: underline;}
      }
      br { display: none; }
    }
  }
  .pageThanks {
    #ContBox01 {
      .contSubBox {
        a.tel { pointer-events: none; }
        p {
          font-size: 18px;
          line-height: 2.25;
        }
        span {
          display: block;
          text-align: center;
          line-height: 2;
          margin-top: 5px;
        }
        .telWrap {
          width: 370px;
          margin: 49px auto 0;
          padding: 30px 0 20px;
          p:before { transform: translate(5px, 1px); }
          span { font-size: 16px; }
        }
        .topBtn {
          margin-top: 60px;
          width: 290px;
          span {
            font-size: 16px;
            margin-left: .1em;
            margin-top: 0;
          }
        }
      }
    }
  }

  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #MainImg {
      .innerBasic {
        min-width: 1000px;
        height: 594px;
        .mainImgBg {
          background-image: url(../img/contents/top/mainimg.jpg);
        }
        .textArea {
          max-width: 488px;
          .text {
            width: 191px;
            height: 166px;
            background-size: 191px auto;
            top: 106px;
            left: -99px;
            span {
              font-size: 18px;
              letter-spacing: .15em;
              transform: translateY(-2px);
              small { margin-left: .35em; }
            }
          }
          .title {
            width: 488px;
            height: 195px;
            background-size: 488px auto;
            transform: translateY(185px);
            span {
              font-size: 24px;
              margin-top: 68px;
            }
            h2 { margin-top: 8px; }
          }
        }
        .import {
          width: 1000px;
          bottom: 31px;
          .importIn {
            .title { width: 150px; }
            .cont {
              width: calc(100% - 150px);
              padding: 14px 30px;
              .contList {
                height: 24px;
                display: flex;
                justify-content: left;
                align-items: center;
                margin-top: 6px;
              }
              time {
                margin-right: 30px;
                width: 111px;
                display: inline-block;
              }
              a { width: calc(100% - 141px); }
            }
          }
        }
      }
    }
    #Main {
      #ContBox01 {
        &:before { display: none; }
        .contSubBox {
          max-width: 1280px;
          padding: 41px 0 101px;
          position: relative;
          margin: 0 auto;
          &:before {
            position: absolute;
            content: '';
            width: 287px;
            height: 223px;
            background: url(../img/contents/illust_01.png) no-repeat top left;
            background-size: 287px auto;
            top: -38px;
            left: -86px;
          }
          .vrTextWrap {
            width: 1000px;
            margin: 0 auto;
            justify-content: flex-end;
            .textWrap {
              writing-mode: vertical-rl;
              -ms-writing-mode: tb-rl;
              -webkit-writing-mode: vertical-rl;
              margin-top: 92px;
              margin-right: 37px;
              p {
                margin-left: 40px;
                font-size: 18px;
                line-height: 2.2;
                &:last-of-type { margin-left: 0; }
                .text-num {
                  -webkit-text-combine: horizontal;
                  -ms-text-combine-horizontal: all;
                  text-combine-upright: all;
                }
              }
            }
            .vrText {
              height: 383px;
              &:first-of-type {
                margin-top: 91px;
                margin-right: 49px;
              }
            }
            .catchText {
              width: 360px;
              padding-top: 50px;
              padding-right: 37px;
              background-size: 360px auto;
              background-position: right top;
              margin-right: 32px;
              margin-left: 0;
              .vrText {
                height: 384px;
                margin-left: 57px;
                &:first-of-type {
                  height: 309px;
                  margin: 0;
                }
              }
            }
          }
          .pageBtn {
            position: absolute;
            bottom: 60px;
            left: 30px;
            width: 240px;
          }
        }
      }
      #ContBox02 {
        .contSubBox { padding: 0; }
      }
      #ContBox03 {
        padding-top: 60px;
        padding-bottom: 89px;
        a.button {
          width: 360px;
          height: 70px;
          line-height: 72px;
          background-position: right 15px top 31px;
          padding-right: 0;
          margin-bottom: 59px;
          span { font-size: 20px; }
        }
        .contSubBox {
          display: flex;
          height: 100%;
          padding: 0;
          .subBoxTitle {
            width: 91px;
            height: auto;
            border-right: 1px solid #34a664;
            border-bottom: 0;
            padding: 0;
            h3 {
              display: inline-flex;
              width: 25px;
              font-size: 0;
              background-size: 23px auto;
              background-position: top left;
              background-repeat: no-repeat;
              top: 0;
              &:after {
                right: auto;
              }
            }
          }
          .subBoxIn {
            width: calc(100% - 91px);
            padding-left: 59px;
          }
        }
        .contSubBox01 {
          .subBoxTitle {
            h3 {
              height: 100px;
              background-image: url(../img/contents/top/text_05.png);
              transform: translateY(-3px);
              &:after {
                width: 60px;
                height: 61px;
                background-size: 60px auto;
                bottom: -66px;
              }
            }
          }
          .subBoxIn {
            position: relative;
            padding-bottom: 55px;
            .listWrap {
              padding-top: 7px;
              .list {
                width: 100%;
                padding: 17px 0 16px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                &:last-of-type { border-bottom: 0; }
                time {
                  width: 130px;
                  display: block;
                }
                a {
                  width: 720px;
                  span {
                    -webkit-line-clamp: 1;
                  }
                }
              }
            }
            .pageBtn {
              width: 240px;
              background-position: top 21px right 15px;
              position: absolute;
              left: auto;
              right: 0;
              bottom: 0;
              span { margin-left: 4px; }
            }
          }
        }
        .contSubBox02 {
          height: 540px;
          margin-top: 60px;
          .subBoxTitle {
            h3 {
              height: 201px;
              background-image: url(../img/contents/top/text_06.png);
              transform: translateY(-4px);
              &:after {
                width: 47px;
                height: 45px;
                background-size: 47px auto;
                bottom: -50px;
                left: 16px;
              }
            }
          }
          .subBoxIn {
            display: flex;
            justify-content: space-between;
            align-items: end;
            padding-bottom: 0;
            position: relative;
            &:before {
              position: absolute;
              content: '';
              width: 222px;
              height: 189px;
              background: url(../img/contents/illust_02.png) no-repeat center;
              background-size: 222px auto;
              top: -26px;
              right: 63px;
            }
            .calender {
              width: 610px;
              height: 540px;
              margin: 0;
              position: relative;
              z-index: 1;
            }
            small {
              position: absolute;
              width: 210px;
              line-height: 1.9;
              margin-top: 0;
              right: 0;
              bottom: -6px;
            }
          }
        }
      }
      #ContBox04 {
        .contSubBox {
          padding: 0;
          padding-top: 80px;
        }
      }
    }
  }

  /*
  霊園のご紹介
  -------------------------------------*/
  #PageIntroduction.pageIndex {
    #MainImg {
      .innerBasic {
        height: 440px;
        h2 {
          font-size: 40px;
          letter-spacing: .15em;
          margin-left: .08em;
          &:after {
            width: 225px;
            height: 164px;
            background-size: 225px auto;
            top: -75px;
            right: -120px;
          }
        }
      }
    }
    #PageNav {
      width: 100%;
      background-color: #e0f0f8;
      .pageNavIn {
        width: 1000px;
        height: 50px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .link {
          transform: translateY(1px);
          a {
            display: block;
            font-size: 16px;
            line-height: 1;
            letter-spacing: .05em;
            margin-left: .05em;
            margin-right: 2.95em;
            padding-left: 15px;
            background: url(../img/contents/arrow_11.png) no-repeat center left;
            background-size: 10px auto;
          }
          &:last-of-type a { margin-right: 0; }
        }
      }
    }
    #Main {
      margin-top: 53px;
      .contBox {
        h3 {
          span {
            font-size: 24px;
            letter-spacing: .15em;
            margin-left: 0;
            width: 160px;
            height: 84px;
            line-height: 104px;
            padding-right: 0.767em;
            background-size: 94px auto;
          }
        }
        .contSubBox {
          padding: 0;
          .showBox .showBoxIn {
            .listArea .list { letter-spacing: 0; }
          }
        }
      }
      #ContBox01 {
        .contSubBox {
          margin-top: 59px;
          padding-bottom: 120px;
          .linkBox {
            &:first-of-type {
              .boxImg .boxLogo {
                img { width: 90px; }
              }
            }
            &:nth-of-type(2) {
              .boxImg .boxLogo {
                img { width: 105px; }
              }
            }
            &:last-of-type {
              .boxImg .boxLogo {
                img { width: 81px; }
              }
            }
          }
        }
      }
      #ContBox02 {
        h3 {
          span {
            width: 212px;
            margin-left: .15em;
            padding-right: 1px;
          }
        }
        .contSubBox {
          margin-top: 29px;
          padding: 0;
          .mapImg {
            margin-top: 88px;
            pointer-events: none;
          }
          small { display: none; }
        }
        .imgBox { margin-top: 88px; }
      }
      #ContBox03 {
        padding-top: 80px;
        padding-bottom: 120px;
        h3 span {
          margin-left: 55px;
          padding-right: 49px;
        }
        .pdfBtn { margin-top: 29px; }
        .contSubBox {
          margin-top: 103px;
          .click {
            height: auto;
            color: #222;
            text-align: center;
            background-color: transparent;
            background-image: url();
            padding: 0;
            pointer-events: none;
            h4 {
              display: inline-block;
              font-size: 24px;
              line-height: 2;
              letter-spacing: .05em;
            }
          }
          .showBox {
            display: block;
            margin-top: 17px;
            padding: 0;
            .showBoxIn {
              width: 1000px;
              table {
                margin-top: 29px;
                caption {
                  height: auto;
                  font-size: 20px;
                  line-height: 1;
                  padding: 25px 0;
                }
                th,td {
                  font-size: 18px;
                  line-height: 1;
                  padding: 26px 19px 25px;
                }
                th {
                  width: 66.5%;
                  span { font-size: 14px; margin-left: 5px; }
                }
                td {
                  width: 33.5%;
                }
                &:nth-of-type(2n) {
                  th,td { font-size: 16px; }
                }
              }
              .scrollBox {
                width: 100%;
                padding: 0;
                overflow-x: hidden;
                table {
                  width: 100% !important;
                  caption {
                    text-align: center;
                    padding-left: 0;
                  }
                }
              }
              .listArea {
                margin-top: 10px;
                .list {
                  line-height: 1.85;
                  margin-top: 0;
                }
              }
            }
          }
        }
        .contSubBox01 {
          margin-top: 78px;
          .showBox {
            .showBoxIn {
              table {
                tr.firstLine {
                  th,td { padding-bottom: 24px;}
                }
              }
              table:last-of-type {
                caption {
                  font-size: 18px;
                  height: auto;
                  line-height: 1;
                  padding: 17px 0 15px;
                }
                tr.firstLine {
                  th,td { padding: 17px 19px 16px; }
                }
                tr:nth-of-type(2),
                tr:last-of-type {
                  td {
                    text-align: center;
                    font-size: 16px;
                    line-height: 2;
                    padding: 15px 0 10px;
                  }
                }
                tr:nth-of-type(3), tr:nth-of-type(4), tr:nth-of-type(5),
                tr:nth-of-type(9), tr:nth-of-type(10) {
                  th, td, td.colorBlock { padding: 17px 19px 16px; }
                  td.colorBlock { width: 332px; }
                }
                th, td { padding: 22px 19px 21px; }
              }
            }
          }
        }
        .contSubBox02 {
          margin-top: 105px;
          .showBox {
            margin-top: 16px;
            .showBoxIn {
              .scrollBox table {
                tr.firstLine {
                  th { padding: 26px 19px 25px; }
                }
                tr:nth-of-type(n+3) {
                  td:first-of-type { padding: 26px 19px 25px; }
                }
                td { padding: 16px 19px 15px; }
              }
              table.second {
                margin-top: 29px;
                caption {
                  font-size: 18px;
                  height: auto;
                  line-height: 1;
                  padding: 17px 0 16px;
                }
                tr.firstLine {
                  th {
                    font-size: 16px;
                    padding: 16px 19px;
                    text-align: center;
                  }
                }
                th {
                  font-size: 16px;
                  padding: 18px 19px 15px;
                  text-align: left;
                }
                td {
                  font-size: 16px;
                  padding: 18px 19px 15px;
                }
              }
            }
          }
        }
        .contSubBox03 {
          .showBox {
            margin-top: 16px;
            .showBoxIn {
              .scrollBox {
                table {
                  tr.firstLine {
                    td { padding: 26px 19px 25px; }
                  }
                }
              }
              table.second {
                margin-top: 15px;
                caption {
                  font-size: 18px;
                  height: auto;
                  line-height: 1;
                  padding: 17px 0 16px;
                }
                tr.firstLine {
                  td { padding: 17px 19px 15px; }
                }
                th { font-size: 16px; padding-left: 19px; }
                td { font-size: 16px; padding: 17px 19px 16px; }
              }
              .textArea {
                display: flex;
                margin-top: 57px;
                .left {
                  width: calc(100% - 550px);
                  padding-right: 35px;
                  ul {
                    transform: translateY(-6px);
                    li {
                      line-height: 2;
                      padding-left: 20px;
                      &:before { top: 13px; }
                    }
                  }
                  span { margin-top: -2px; }
                }
                .right {
                  width: 550px;
                  margin-top: 0;
                  dl {
                    dd {
                      font-size: 14px;
                      line-height: 1.43;
                      padding-top: 12px;
                    }
                  }
                }
              }
            }
          }
        }
        .contSubBox04 {
          .showBox {
            margin-top: 15px;
            .showBoxIn {
              .itemArea {
                display: flex;
                justify-content: space-between;
                .item {
                  width: 33.3%;
                  padding-bottom: 10px;
                  flex-direction: column;
                  margin-top: 0 !important;
                  dt {
                    width: 100%;
                  }
                  dd {
                    width: 100%;
                    padding-top: 21px;
                    padding-left: 0;
                    align-items: center;
                    font-size: 18px;
                    line-height: 1;
                    small { top: 10px; }
                  }
                }
                .item:first-of-type {
                  width: 33.2%;
                }
              }
              .listArea { margin-top: 24px; }
            }
          }
        }
        .contSubBox05 {
          margin-top: 74px;
          .showBox {
            .showBoxIn {
              table.cleaning {
                margin-top: 28px;
                caption {
                  font-size: 18px;
                  height: auto;
                  line-height: 1;
                  padding: 17px 0 16px;
                }
                th,td { font-size: 16px; }
                td { padding: 16px 19px !important; }
                &:last-of-type {
                  margin-top: 23px;
                }
              }
            }
          }
        }
        .pdfBtn_large {
          margin-top: 85px;
          width: 480px;
          height: 70px;
          line-height: 70px;
          span {
            height: 100%;
            background-position: right center;
          }
        }
      }
      #ContBox04 {
        .contSubBox {
          padding-top: 82px;
          .boxTitle {
            h3 {
              height: 94px;
              line-height: 100px;
              background-size: 94px auto;
              background-position: bottom 12px right 329px;
              margin-left: 0;
            }
          }
          .boxContent {
            margin-top: 27px;
            .boxTextWrap {
              margin-top: 25px;
              .boxInfo {
                height: 190px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 60px;
                .infoLeft {
                  padding-left: 4px;
                  img { width: 329px;}
                  p { margin-top: 18px; }
                }
                .infoRight {
                  margin-top: 0;
                  .telBtns {
                    width: 477px;
                    padding-bottom: 24px;
                    .telBtn {
                      width: auto;
                      pointer-events: none;
                      a {
                        font-size: 16px;
                        line-height: 1;
                        letter-spacing: .05em;
                        text-align: center;
                        background-color: transparent !important;
                        padding: 7px 0 0;
                        span {
                          font-size: 32px;
                          line-height: 1;
                          letter-spacing: .05em;
                          margin-top: 0;
                          transform: translateY(10px);
                        }
                      }
                    }
                  }
                  .opening {
                    font-size: 14px;
                    line-height: 1;
                    margin: 0;
                    margin-top: 20px;
                  }
                }
              }
            }
          }
        }
        .noticeBox {
          margin-top: 133px;
          padding-bottom: 53px;
        }
      }
      #ContBox05 {
        background-color: #fff;
        padding-bottom: 120px;
        h3{
          margin-bottom: 48px;
          span{
            margin-left: -10px;
          }
        }
        .lead{
          font-size: 24px;
          margin-bottom: 67px;
        }
        .contSubBox {
          padding: 60px 150px 65px;
          .boxWrap{
            display: flex;
            justify-content: space-between;
            margin-bottom: 45px;
            max-height: 285px;
            &.is_open{
              max-height: 1100px;
            }
            .imgBox{
              width: 280px;
              height: auto;
              margin-left: 5px;
              img{
                width: 100%;
                height: auto;
              }
            }
            .infoBox{
              .labelBox{
                margin-bottom: 22px;
              }
              .officeLogo{
                &:hover{
                  opacity: 0.7;
                }
              }
            }
          }
          .toggleBtn{
            width: 480px;
            padding: 21px 0;
            &:hover{
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

  /*■■■■■下層ページ■■■■■*/
  #PageIntroduction.pageKazenomori,
  #PageIntroduction.pageSancgarden,
  #PageIntroduction.pageSora,
  #PageDaichi.pageIndex {
    #MainImg {
      .innerBasic {
        height: 440px;
        h2 {
          width: 260px;
          height: 260px;
        }
      }
    }
    #Main {
      .contBox {
        h3 {
          font-size: 24px;
          letter-spacing: .15em;
        }
        .contSubBox {
          p {
            font-size: 18px;
            line-height: 2.23;
            text-align: center;
          }
        }
      }
      #ContBox01 {
        margin-top: 78px;
        padding-bottom: 108px;
        h3 {
          font-size: 28px;
          line-height: 1.85;
        }
        .contSubBox {
          margin-top: 35px;
          p { margin-bottom: 40px; }
        }
      }
      #ContBox02 {
        padding-top: 120px;
        padding-bottom: 148px;
        h3 {
          span { right: 12px; }
          &:after {
            width: 94px;
            height: 84px;
            background-size: 94px auto;
            top: -40px;
            right: -54px;
          }
        }
        .contSubBox {
          padding: 0;
          margin-top: 55px;
          .galleryWrap {
            margin-top: 90px;
            display: flex;
            justify-content: space-between;
            h4 {
              width: 90px;
              border-bottom: 0;
              border-right: 1px solid #34a664;
              padding: 0;
              span {
                display: inline-block;
                width: 21px;
                height: 100%;
                font-size: 0;
                letter-spacing: .075em;
                background: url(../img/contents/introduction/text_01.png) no-repeat top center;
                background-size: 21px auto;
                &:after {
                  width: 56px;
                  height: 48px;
                  background-size: 56px auto;
                  top: auto;
                  right: -52px;
                  bottom: 28px;
                }
              }
            }
            .galleryIn {
              width: 850px;
              margin-top: 0;
              .photo {
                a {
                  overflow: hidden;
                  img {
                    transform: scale(1);
                    transition: transform .5s ease;
                  }
                  &:after {
                    width: 30px;
                    height: 30px;
                    background-size: 12px auto;
                    right: 10px;
                    bottom: 10px;
                  }
                  &:hover {
                    img { transform: scale(1.1); }
                  }
                }
              }
            }
          }
        }
      }
      #ContBox03 {
        background-size: 150px auto;
        padding: 121px 0 120px;
        .contSubBox {
          margin-top: 30px;
          position: relative;
          p {
            font-size: 16px;
            line-height: 2;
          }
          &:before {
            width: 287px;
            height: 223px;
            background-size: 287px auto;
            top: -235px;
            left: -57px;
          }
          .contactBtn {
            margin: 22px auto 0;
            width: 680px;
            height: 70px;
            line-height: 74px;
            background-size: 18px auto;
            background-position: center right 30px;
            p {
              font-size: 20px;
              line-height: 1;
              position: relative;
              left: 4px;
              &:before { transform: translate(-11px, 9px); }
            }
            &:hover {
              opacity: .7;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  /*■■■■■風の森聖地■■■■■*/
  #PageIntroduction.pageKazenomori {
    #MainImg {
      .innerBasic {
        h2 { background-size: 135px auto; }
      }
    }
    #Main {
      #ContBox01 {
        background-size: 1000px auto;
      }
    }
  }
  /*■■■■■サンクガーデン メモリアル 16■■■■■*/
  #PageIntroduction.pageSancgarden {
    #MainImg {
      .innerBasic {
        h2 { background-size: 158px auto; }
      }
    }
    #Main {
      #ContBox01 {
        margin-top: 80px;
        background-size: 310px auto;
        background-position: bottom -2px right -54px;
        .contSubBox {
          margin-top: 38px;
        }
      }
      #ContBox02 { padding-bottom: 120px; }
    }
  }
  /*■■■■■永代供養墓 そら■■■■■*/
  #PageIntroduction.pageSora {
    #MainImg {
      .innerBasic {
        h2 {
          background-size: 120px auto;
          background-position: center top 69px;
        }
      }
    }
    #Container { background-size: auto 1011px; }
    #Main {
      #ContBox01 {
        margin-top: 91px;
        .foreword {
          text-align: center;
          font-size: 18px;
          line-height: 1;
          margin-bottom: 47px;
        }
        .contSubBox { margin-top: 37px; }
      }
      #ContBox02 {
        padding-bottom: 124px;
        .contSubBox {
          table {
            tr:nth-of-type(2) {
              td {
                 a {
                  padding-right: 30px;
                  background-size: 18px auto;
                 }
              }
            }
          }
        }
      }
    }
  }

  /*
  清泰寺について
  -------------------------------------*/
  #PageSeitaiji.pageIndex {
    #MainImg {
      .innerBasic {
        height: 440px;
        h2 {
          font-size: 40px;
          letter-spacing: .15em;
          &:after {
            width: 225px;
            height: 164px;
            background-size: 225px auto;
            top: -70px;
            right: -27px;
          }
        }
      }
    }
    #Main {
      .contSubBox {
        p {
          font-size: 18px;
          text-align: center;
        }
      }
      #ContBox01 {
        margin-top: 79px;
        padding-bottom: 120px;
        h3 {
          font-size: 28px;
          line-height: 1.86;
        }
        .contSubBox {
          margin-top: 34px;
          p { line-height: 2.25; }
          .imgWrap {
            margin-top: 48px;
            &:before {
              width: 94px;
              height: 124px;
              background-size: 94px auto;
              bottom: -28px;
              left: -81px;
            }
            &:after {
              width: 155px;
              height: 289px;
              background-size: 155px auto;
              bottom: 86px;
              right: -96px;
            }
          }
        }
      }
      #ContBox02 {
        padding: 120px 0 119px;
        h3 {
          font-size: 24px;
          &:after {
            width: 94px;
            height: 84px;
            background-size: 94px auto;
            top: -39px;
            right: -42px;
          }
        }
        .contSubBox {
          margin-top: 57px;
          overflow: auto;
          p { line-height: 1; }
          .tableWrap {
            margin-top: 59px;
            float: left;
            table {
              width: 666px;
              tr {
                td { padding: 20px 19px 18px; }
                &:first-of-type td {
                  a { padding-right: 26px; }
                }
                &:nth-of-type(2) td { padding: 19px 19px 18px; }
                &:last-of-type td {
                  padding: 19px;
                  a {
                    pointer-events: none;
                    color: #222;
                  }
                }
              }
              th { width: 200px; }
            }
          }
          .imgWrap {
            margin-top: 59px;
            float: right;
            width: 304px;
            img {
              width: 100%;
              &:last-of-type { margin-top: 1px; }
            }
          }
        }
      }
      #ContBox03 {
        padding: 88px 0 116px;
        h3 {
          width: 1000px;
          margin: 0 auto;
          font-size: 24px;
          padding: 0;
        }
        .contSubBox {
          margin-top: 30px;
          .subBoxIn {
            border-width: 8px;
            display: flex;
            padding: 52px 52px 56px;
            .imgArea {
              width: 390px;
              margin-right: 50px;
            }
            .textArea {
              width: calc(100% - 440px);
              margin: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              p {
                font-size: 16px;
                text-align: left;
                letter-spacing: .05em;
                line-height: 2;
                transform: translateY(-3px);
              }
              .lists {
                margin-top: 22px;
                transform: translateY(-2px);
                .list {
                  margin-top: 16px;
                  a {
                    pointer-events: none;
                    color: #222;
                  }
                  &:last-of-type span {
                    padding: 0 4px 0 5px;
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  お知らせ
  -------------------------------------*/
  #PageNews {
    #PageTitle {
      h2 {
        display: inline-block;
        transform: translateX(4px);
        &:after {
          width: 100px;
          height: 101px;
          background-size: 100px auto;
          top: -7px;
          right: -88px;
        }
      }
    }
    #Main {
      #ContBox01 {
        .contSubBox {
          position: relative;
          &:before {
            width: 126px;
            height: 189px;
            background-size: 126px auto;
            top: -53px;
            left: -57px;
          }
        }
      }
    }
  }

  #PageNews.pageIndex {
    #Main {
      margin-top: 22px;
      #ContBox01 {
        padding-bottom: 124px;
      }
    }
  }

  /*■■■■■下層ページ■■■■■*/
  #PageNews.pageEntry {
    #Main {
      #ContBox01 {
        margin-top: 22px;
        padding-bottom: 134px;
      }
    }
  }

  /*
  よくある質問
  -------------------------------------*/
  #PageFaq {
    #PageTitle {
      h2 {
        margin-left: 6px;
        &:after {
          width: 81px;
          height: 66px;
          background-size: 81px auto;
          right: -75px;
          bottom: 16px;
        }
      }
    }
    #ContBox01 {
      .contSubBox {
        position: relative;
        &:before {
          width: 238px;
          height: 313px;
          background-size: 238px auto;
          top: -110px;
          left: -84px;
        }
        &:after {
          display: block;
          content: '';
          clear: both;
        }
        .boxLeft {
          float: left;
          width: 720px;
        }
        #BlogNavi {
          float: right;
          width: 220px;
          margin: 0;
          .category {
            h4 {
              padding-bottom: 12px;
            }
            ul {
              li a:hover {
                opacity: .7;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

  #PageFaq.pageIndex {
    #Main {
      padding-bottom: 192px;
      #ContBox01 {
        .contSubBox {
          .catBtnWrap {
            .catBtn {
              width: 320px;
              height: 320px;
              margin: 0;
              margin-right: 20px;
              a {
                text-align: center;
                background-image: url() !important;
                border-radius: 16px;
                img {
                  display: block;
                  position: relative;
                  left: 0 !important;
                  margin: 0 auto;
                }
                span {
                  margin-left: 0;
                  position: absolute;
                  width: 100%;
                  font-size: 20px;
                  bottom: 40px;
                  left: 0;
                }
                &:hover { opacity: .7; }
              }
              &:first-of-type {
                a img {
                  width: 172px;
                  transform: translateY(-68px);
                }
              }
              &:nth-of-type(2) {
                a img {
                  width: 97px;
                  transform: translate(10px, -75px);
                }
              }
              &:nth-of-type(3) {
                margin-right: 0;
                a img {
                  width: 93px;
                  transform: translate(-2px, -69px);
                }
              }
              &:nth-of-type(4) {
                margin-top: 20px;
                a img {
                  width: 76px;
                  transform: translateY(-72px);
                }
              }
              &:nth-of-type(5) {
                margin-top: 20px;
                a img {
                  width: 90px;
                  transform: translateY(-77px);
                }
              }
              &:last-of-type {
                margin-top: 20px;
                margin-right: 0;
                a img {
                  width: 98px;
                  transform: translate(-1px, -60px);
                }
              }
            }
          }
        }
      }
    }
  }

  /*■■■■■下層ページ■■■■■*/
  #PageFaq.pageCategory {
    #Main {
      #ContBox01 {
        padding-top: 25px;
        padding-bottom: 117px;
        #CatName {
          width: 1000px;
          padding: 0;
          margin: 0 auto;
          h3 {
            font-size: 28px;
            img {
              width: 68px;
              margin-right: 13px;
            }
            &.cemetery img { top: -10px; }
            &.grave img { width: 40px; top: -6px;}
            &.liturgy img { width: 40px; top: -8px; }
            &.stupa img { width: 30px; top: -8px; }
            &.clean img { width: 40px; top: -3px; }
            &.other img { width: 50px; top: -8px; }
          }
        }
        .contSubBox {
          margin-top: 20px;
          .listWrap {
            .list .listCont {
              padding-top: 47px;
            }
          }
          .moreBtn { margin-top: 60px; }
        }
      }
    }
  }

  #PageFaq.pageEntry {
    #Main {
      #ContBox01 {
        padding-bottom: 128px;
        .contSubBox {
          &:before { top: -44px; }
          .entryWrap {
            .entryIn {
              padding: 56px 56px 77px;
              p {
                line-height: 2;
                letter-spacing: .035em;
                margin-top: 32px;
              }
            }
            .entryTag:last-of-type { margin-bottom: 21px; }
            .entryTitle {
              font-size: 28px;
              letter-spacing: .05em;
              line-height: 1.7;
              text-align: justify;
              padding-bottom: 19px;
              margin-bottom: 52px;
            }
          }
          .btnWrap {
            margin-top: 30px;
            width: 100%;
            a:nth-of-type(2) { top: 0; }
          }
          #BlogNavi h4 { padding-bottom: 12px; }
        }
      }
    }
  }

  /*
  お問い合わせ
  -------------------------------------*/
  #PageContact, #PageToba, #PageCleaning, #PageTenrei {
    #Main {
      .contBox {
        line-height: 2;
        p { line-height: 2 }
        .noticeList {
          margin-top: 15px;
          .notice { padding-left: 20px; }
        }
      }
      #ContBox01 {
        .contSubBox {
          p {
            font-size: 18px;
            line-height: 2.25;
            letter-spacing: .05em;
            text-align: center;
          }
          .privacyBtn:hover {
            text-decoration: none;
            opacity: .7;
          }
        }
      }
    }
  }
  #PageToba, #PageCleaning, #PageTenrei {
    &.pageConfirm {
      #Main #ContBox01 .contSubBox01 {
        .btnArea {
          input:hover { text-decoration: underline; }
        }
      }
    }
  }

  #PageContact.pageIndex {
    #Main {
      margin-top: -18px;
      #ContBox01 {
        padding-bottom: 150px;
        .contSubBox02 {
          margin-top: 21px;
          .showBox {
            table {
              width: 100%;
              th, td { width: 50%; }
              tr td:nth-of-type(2) a {
                color: #222;
                pointer-events: none;
              }
            }
          }
        }
      }
      #ContBox02 {
        background-size: 150px auto;
        padding: 120px 0;
        .contSubBox {
          display: flex;
          justify-content: space-between;
          & > div {
            width: 490px;
            padding: 60px;
            h3 {
              font-size: 24px;
            }
          }
          .telBtn {
            .telWrap {
              margin-top: 29px;
              padding: 30px 0;
              a { pointer-events: none; }
              span {
                font-size: 40px;
                padding-left: 46px;
                &:before {
                  top: 8px;
                  left: 18px;
                }
              }
              small { font-size: 16px; }
            }
          }
          .contactBtns {
            margin-top: 0;
            .contactBtn {
              height: 60px;
              &.tenrei {
                margin-top: 28px;
                p {
                  padding-left: 37px;
                  transform: translate(-50%, -40%);
                  &:before { left: -3px; }
                }
              }
            }
          }
        }
      }
      #ContBox03 {
        padding: 46px 0 71px;
        .contSubBox {
          .boxTitle {
            h3 {
              font-size: 24px;
              margin-left: 4px;
              &:after {
                width: 94px;
                height: 84px;
                background-size: 94px auto;
                top: -40px;
                right: -67px;
              }
            }
          }
          p.foreword {
            text-align: center;
            line-height: 2;
            margin: 73px 0 52px;
          }
          .textBox {
            margin-top: 52px;
            &:nth-of-type(n+3) { margin-top: 55px; }
            p {
              line-height: 1.86;
              letter-spacing: .05em;
            }
            ul {
              li { line-height: 1.86; }
            }
            a {
              pointer-events: none;
              color: #222;
            }
          }
        }
      }
    }
  }

  /*■■■■■お塔婆お申し込み■■■■■*/
  #PageToba.pageIndex {
    #Main {
      margin-top: -24px;
      padding-bottom: 92px;
      #ContBox01 {
        .contSubBox {
          h3 { margin-top: 58px; }
        }
      }
      #ContBox02 {
        .contSubBox {
          .formWrap {
            margin-top: 51px;
            .formIn {
              tr {
                &:nth-of-type(3) td input { width: 190px; }
                &:nth-of-type(16) td input { width: 70px; }
                &:nth-of-type(19) {
                  align-items: start;
                  th {
                    position: relative;
                    top: -2px;
                  }
                  span:nth-of-type(3),
                  span:nth-of-type(5) { margin-right: 0; }
                }
                &:nth-of-type(20) td input,
                &:nth-of-type(21) td input {
                  width: 280px;
                }
                &:nth-of-type(22) td input,
                &:nth-of-type(23) td input {
                  width: 410px;
                }
                &:last-of-type {
                  align-items: start;
                  th {
                    position: relative;
                    top: 5px;
                  }
                  td textarea { height: 200px; }
                }
              }
            }
            //wp contact form
            input.wpcf7c-conf,
            textarea.wpcf7c-conf {
              padding-left: 15px;
              padding-right: 15px;
            }
            tr:nth-of-type(4) td input.wpcf7c-conf,
            tr:nth-of-type(5) td input.wpcf7c-conf,
            tr:nth-of-type(17) td input.wpcf7c-conf { width: 330px; }
            textarea.wpcf7c-conf { width: 100%; height: auto; }
            span.wpcf7-list-item {
              label { margin-right: 21px; }
              &.first { label { margin-bottom: 18px; } }
              &:nth-of-type(4),
              &:last-of-type { margin-left: 1em; }
            }
            span.wpcf7-form-control-wrap { width: auto; }
            .sendBtn {
              margin-top: 60px;
              margin-bottom: 64px;
              .wpcf7-confirm { margin-bottom: 0; }
              .wpcf7-back { margin: 0; transform: translateY(-3px); }
            }
          }
        }
      }
    }
  }
  #PageToba.pageConfirm {
    #Main {
      margin-top: -24px;
      padding-bottom: 153px;
      #ContBox01 {
        .contSubBox01 {
          p { line-height: 2.25; }
        }
      }
      #ContBox02 {
        .contSubBox {
          .formWrap {
            margin-top: 78px;
            .formIn {
              tr {
                padding: 24px 0 23px !important;
                &:last-of-type {
                  align-items: start;
                }
              }
              th { width: 312px; }
              td {
                width: 688px;
                margin-top: 0;
                line-height: 2;
                span.toba {
                  input.wpcf7c-conf { width: inherit; }
                }
              }
            }
            .sendBtn {
              margin-top: 60px;
              position: relative;
              .textBack {
                position: absolute;
                margin: 0;
                top: 53%;
                left: 0;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
  }
  #PageToba.pageThanks {
    #Main {
      margin-top: -25px;
      padding-bottom: 158px;
      #ContBox01 {
        .contSubBox {
          .telWrap {
            p {
              font-size: 40px;
              letter-spacing: .1em;
            }
          }
        }
      }
    }
  }

  /*■■■■■掃除代行お申し込み■■■■■*/
  #PageCleaning {
    #MainImg {
      #PageTitle { padding-top: 83px; }
    }
    #Main { margin-top: -28px; }
  }

  #PageCleaning.pageIndex {
    #Main {
      padding-bottom: 160px;
      #ContBox01 {
        .contSubBox {
          h3 { margin-top: 59px; }
        }
      }
      #ContBox02 {
        margin-top: 32px;
        .click {
          width: 1000px;
          margin: 0 auto;
          background: transparent url();
          padding: 0;
          pointer-events: none;
          p {
            font-size: 24px;
            color: #222;
          }
        }
        .showBox {
          display: block;
          margin-top: 3px;
          .textBox {
            .mainPrice {
              width: 100% !important;
              caption {
                text-align: center;
                padding-left: 0;
                font-size: 20px;
                height: 70px;
                line-height: 70px;
              }
              th {
                font-size: 18px;
                padding: 17px 18px 16px;
              }
              td {
                font-size: 18px;
                padding: 17px 13px 16px;
              }
            }
            .subPrice {
              caption {
                font-size: 18px;
                padding: 9px 0;
              }
              tr:last-of-type {
                th,td { padding-top: 9px; padding-bottom: 8px; }
              }
              th {
                font-size: 16px;
                padding: 15px 18px 14px;
              }
              td {
                font-size: 16px;
                padding: 15px 13px 14px;
              }
            }
            ul {
              li { line-height: 1.85; }
            }
            &:last-of-type { margin-top: 25px; }
          }
          .scrollBox {
            width: 100%;
            padding-bottom: 0;
            overflow-x: hidden;
          }
        }
      }
      #ContBox03 {
        .formWrap {
          margin-top: 55px;
          .formIn {
            td {
              input { width: 340px; }
            }
            tr:nth-of-type(3) {
              align-items: start;
              padding: 20px 0 25px !important;
              th { transform: translateY(-4px); }
            }
            tr:nth-of-type(4) {
              td {
                input { width: 190px; }
                small {
                  display: inline;
                  margin-top: 0;
                }
              }
            }
            tr:nth-of-type(5) {
              align-items: start;
              th { transform: translateY(5px); }
              td {
                span.zipcode input {
                  width: 190px;
                  margin-left: 4px;
                }
                span.address input {
                  display: block;
                  width: 100%;
                }
              }
            }
            tr:nth-of-type(6) {
              td input { width: 280px; }
            }
            tr:nth-of-type(7), tr:nth-of-type(8) {
              td input { width: 410px; }
            }
            tr:nth-of-type(9) {
              align-items: start;
              th { transform: translateY(4px); }
              td {
                p.sub {
                  input:last-of-type { margin-top: 0; }
                  span:nth-of-type(3), span:nth-of-type(4) {
                    top: 0;
                  }
                }
                span.sub {
                  display: inline-block;
                  .hana02 { margin-top: 0; }
                }
                br:nth-of-type(4) { display: block; }
              }
            }
            tr:nth-of-type(10) {
              td {
                input { margin-bottom: 0px; }
                span.index_display { margin-left: 10px; }
                small.index_display { margin-left: 0; margin-top: 5px; }
              }
            }
            tr:last-of-type {
              align-items: start;
              th { transform: translateY(4px); }
              textarea { height: 200px; }
              textarea.wpcf7c-conf { width: auto; height: auto; }
            }
          }
          //wp contact form 7
          span.wpcf7-form-control-wrap {
            width: auto;
            &.address, &.message { width: 100%; }
            .wpcf7-radio {
              .wpcf7-list-item {
                &.first { margin-bottom: 10px; }
              }
            }
            &.address {
              input.wpcf7c-conf {
                display: inline-block;
                width: auto;
              }
            }
            input.wpcf7c-conf,
            textarea.wpcf7c-conf {
              padding-left: 15px;
              padding-right: 15px;
            }

          }
          .sendBtn {
            margin-top: 60px;
            .button { width: 483px; }
            .textBack { margin: 0; }
          }
        }
      }
    }
  }
  #PageCleaning.pageConfirm {
    #Main {
      padding-bottom: 170px;
      #ContBox01 {
        .contSubBox02 {
          .formWrap {
            margin-top: 79px;
            .formIn {
              tr { padding: 24px 0 23px !important; }
              th { width: 310px; }
              td {
                width: 690px;
                margin-top: 0;
                p {
                  text-align: left;
                  font-size: 16px;
                  margin-top: -5px;
                  &:first-of-type { margin-top: 0; }
                }
              }
              tr:nth-of-type(1) {
                td { margin-top: 0; }
              }
              tr:nth-of-type(5) {
                align-items: start;
              }
              tr:nth-of-type(9) {
                align-items: start;
                td {
                  margin-top: 0;
                  p {
                    line-height: 2;
                    &:first-of-type { margin-top: 3px; }
                    &:nth-of-type(3) { margin-top: 0px; }
                    &.sub { padding-left: 21px; }
                  }
                }
              }
              tr:nth-of-type(10) {
                span.date { width: 20%; }
              }
              tr:last-of-type { align-items: start; }
            }
            .sendBtn {
              margin-top: 60px;
              position: relative;
              .textBack {
                position: absolute;
                margin: 0;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
  }
  #PageCleaning.pageThanks {
    #Main {
      padding-bottom: 158px;
      #ContBox01 {
        .contSubBox {
          .telWrap {
            p {
              font-size: 40px;
              letter-spacing: .1em;
            }
          }
          .topBtn { margin-top: 60px; }
        }
      }
    }
  }

  /*■■■■■典礼ご予約■■■■■*/
  #PageTenrei {
    #MainImg {
      #PageTitle { padding: 93px 0 74px; }
    }
  }
  #PageTenrei.pageIndex {
    #Main {
      #ContBox01 {
        .contSubBox {
          p { margin-bottom: 58px; }
        }
      }
      #ContBox02 {
        padding-bottom: 144px;
        .contSubBox {
          .formWrap { margin-top: 41px; }
          .formText h3 { letter-spacing: .14em; }
          .formText01 { margin-bottom: 19px; }
          .formText02 {
            margin-top: 51px;
            margin-bottom: 21px;
            h3 { margin-bottom: 10px; }
          }
          .formIn {
            th {
              position: relative;
              padding-left: 63px;
              &:before {
                position: absolute;
                top: 2px;
                left: 0;
                margin-right: 0;
              }
            }
            td {
              p {
                margin-bottom: 13px !important;
                &:nth-of-type(2) { margin-bottom: 0 !important; }
              }
              input.js-datepicker {
                width: 300px;
                margin-right: 5px;
              }
              label {
                width: auto;
                margin-right: 17px;
                margin-bottom: 16px;
                &:last-of-type {
                  margin-right: 0;
                }
              }
              .selector {
                width: 240px !important;
                margin-top: 0;
                span { max-width: unset; }
              }
              span {
                &.wpcf7-form-control-wrap {
                  width: auto;
                }
              }
            }
          }
          .formIn01 {
            tr {
              &:nth-of-type(3) td {
                input { width: 190px; }
              }
              &:nth-of-type(4) td {
                span.wpcf7-list-item { margin-right: 22px; }
                label { margin-bottom: 0; }
              }
              &:nth-of-type(5) td,
              &:nth-of-type(6) td {
                input { width: 410px; }
              }
              &:last-of-type td {
                input { width: 280px; }
              }
            }
          }
          .formIn02 {
            tr {
              align-items: start;
              padding-top: 25px !important;
              &:nth-of-type(1) {
                padding-bottom: 8px !important;
                td {
                  p {
                    margin-bottom: 15px !important;
                    &:nth-of-type(2) { margin-bottom: 0 !important; }
                  }
                  span { width: 100%; }
                  textarea { height: 120px; }
                }
              }
              &:nth-last-of-type(5) td {
                input {
                  width: 100%;
                  height: 120px;
                }
              }
              &:nth-of-type(2),
              &:nth-of-type(3),
              &:nth-of-type(4),
              &:nth-of-type(5),
              &:nth-of-type(6) {
                padding: 20px 0 19px !important;
                align-items: center;
              }
              &:nth-of-type(7) {
                padding-bottom: 0px !important;
                td {
                  padding-bottom: 4px;
                  p { margin-bottom: 16px !important; }
                  .wpcf7-list-item {
                    width: auto;
                    display: inline-block;
                    margin-right: 30px;
                    &:nth-of-type(3), &:last-of-type { margin-right: 0; }
                    &:nth-of-type(4) label, &:last-of-type label { margin-bottom: 0; }
                  }
                  .checker { margin-right: 8px; }
                  label {
                    margin-right: 25px;
                    margin-bottom: 12px;
                    &:nth-of-type(3), &:last-of-type { margin-right: 0; }
                  }
                }
              }
              &:nth-of-type(8) {
                td {
                  input[type="text"] {
                    width: 410px;
                    margin-top: -11px;
                  }
                  label {
                    width: auto;
                    margin-right: 15px;
                    &:last-of-type { margin: 0 0 16px; }
                  }
                  span.religion {
                    overflow: auto;
                    margin-bottom: -22px;
                  }
                  span.wpcf7-list-item{
                    float: left;
                    &:last-of-type { margin-bottom: 0; }
                  }
                  span.wpcf7-not-valid-tip { margin: -10px 0 15px; }
                }
              }
              &:nth-of-type(10) {
                input { width: 240px; }
              }
              &:nth-of-type(11),
              &:nth-of-type(12) {
                padding-bottom: 17px !important;
                td {
                  span.wpcf7-list-item:first-of-type { margin-right: 22px; }
                  label { margin-bottom: 0; }
                }
              }
              &:nth-of-type(13) {
                padding-bottom: 22px !important;
                td label { margin-bottom: 0; }
              }
              &:nth-of-type(13) td,
              &:nth-of-type(15) td,
              &:nth-of-type(16) td {
                span.wpcf7-list-item {
                  margin-right: 22px;
                  width: auto;
                }
              }
              &:nth-of-type(14) {
                padding-bottom: 8px !important;
                td {
                  span { width: 100%; }
                  textarea { height: 120px; }
                }
              }
              &:nth-of-type(15),
              &:nth-of-type(16) {
                padding-bottom: 22px !important;
                td label { margin-bottom: 0; }
              }
              &:nth-of-type(17) {
                padding-bottom: 18px !important;
                td label {
                  margin-right: 23px;
                  margin-bottom: 0;
                }
              }
              &:last-of-type {
                padding: 20px 0 8px !important;
                th { transform: translateY(5px); }
                td {
                  span { width: 100%; }
                  textarea { height: 200px; }
                }
              }
            }
            td {
              margin-top: 0;
            }
          }
          .btnArea {
            margin-top: 60px;
            input {
              width: 480px;
              height: 70px;
              line-height: 70px;
              padding-left: 0.35em;
              transform: translateX(0);
            }
            .ajax-loader {
              position: absolute;
              top: -20px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  #PageTenrei.pageConfirm {
    #Main {
      #ContBox02 {
        padding-bottom: 179px;
        .contSubBox {
          .formWrap {
            margin-top: 66px;
          }
          .formText {
            h3 { font-size: 24px; }
          }
          .formText01 { margin-bottom: 16px; }
          .formIn {
            tr { align-items: start; }
            th:before { top: 2px; }
            td {
              input { width: auto; }
            }
          }
          .formIn01 {
            tr { padding: 22px 0 22px !important; }
          }
          .formIn02 {
            margin-bottom: 16px;
            tr {
              &:nth-of-type(1),
              &:nth-of-type(13),
              &:nth-of-type(14) {
                align-items: start;
              }
              &:nth-of-type(7) td {
                p:nth-of-type(2) { margin-bottom: -9px; }
                .wpcf7-form-control-wrap { transform: translateY(1px); }
                .wpcf7-checkbox {
                  display: block;
                  overflow: auto;
                  .wpcf7-list-item { float: left; }
                }
              }
            }
          }
        }
      }
    }
  }
  #PageTenrei.pageThanks {
    #Main {
      #ContBox01 {
        padding-bottom: 157px;
        .contSubBox {
          & > span {
            text-align: center;
          }
          .telWrap {
            margin-top: 49px;
            .tel {
              p {
                font-size: 40px;
                letter-spacing: .1em;
              }
            }
          }
          .topBtn {
            width: 300px;
          }
        }
      }
    }
  }

  /*
  永代供養墓「大地」
  -------------------------------------*/
  #PageDaichi.pageIndex {
    #Main {
      #ContBox01 {
        margin-top: 0;
        #TopicPath {
          margin-bottom: 86px;
        }
        .foreword {
          font-size: 18px;
          text-align: center;
          margin-bottom: 41px;
        }
        .contSubBox {
          margin-top: 37px;
        }
      }
      #ContBox02 {
        padding-bottom: 107px;
        .contSubBox {
          margin-top: 47px;
          p {
            line-height: 2;
            margin-bottom: 52px;
          }
          table {
            td {
              padding: 19px 20px 18px;
              a[href$="pdf"] {
                background-position: right top 4px;
              }
              a[href^="tel"] {
                color: #222;
                text-decoration: none;
                &:hover {
                  cursor: text;
                }
              }
            }
          }
        }
      }
    }
  }
}


// edge対応
@supports (-ms-ime-align:auto) {
  #PageIntroduction.pageKazenomori,
  #PageIntroduction.pageSancgarden,
  #PageIntroduction.pageSora,
  #PageDaichi.pageIndex {
    #Main {
      #ContBox02 {
        .contSubBox {
          .galleryWrap {
            .galleryIn {
              .photo {
                width: 282.66px;
              }
            }
          }
        }
      }
    }
  }
}

// IE
@media screen\0 {
  #Page.pageIndex #Main {
    #ContBox02 {
      .contSubBox .linkBox a{
        .boxText p {
          letter-spacing: .05em;
          transform: translateY(2px);
        }
      }
      .imgBtn a {
        .pageBtn span { transform: translateY(3px); }
      }
    }
    #ContBox03  {
      .pageBtn span { transform: translateY(3px); }
      .contSubBox02 .subBoxIn { justify-content: flex-start; }
    }
  }
  #PageIntroduction.pageIndex {
    #MainImg .innerBasic {
      h2 { transform: translateX(-50%); }
    }
    #PageNav .pageNavIn {
      .link {
        a {
          line-height: 1.25;
          background-position: left top 3px;
        }
      }
    }
    #Main {
      .pdfBtn span {
        line-height: 46px;
        background-position: right center;
      }
      .pdfBtn_large span { line-height: 80px; }
      #ContBox03 .contSubBox04 {
        .showBox .showBoxIn {
          .itemArea .item {
            dt { height: 235px; }
          }
        }
      }
    }
  }
  #PageIntroduction.pageKazenomori,
  #PageIntroduction.pageSancgarden,
  #PageIntroduction.pageSora,
  #PageDaichi.pageIndex {
    #Main {
      #ContBox02 {
        .contSubBox {
          .galleryWrap .galleryIn {
            .photo {
              width: 282.66px;
            }
          }
        }
      }
    }
  }
  #PageIntroduction.pageSora #Main {
    #ContBox02 .contSubBox {
      .tableWrap table {
        tr:nth-of-type(2) {
          th { padding: 22px 0 15px; }
          td {
            padding: 22px 28px 15px;
            a { background-position: top 3px right; }
          }
        }
      }
    }
  }
  #PageSeitaiji.pageIndex {
    #MainImg .innerBasic {
      h2 { transform: translateX(-50%); }
    }
    #Main {
      #ContBox02 .contSubBox {
        p { line-height: 1.5; }
        .tableWrap table{
          tr:first-of-type {
            th {
              display: table-cell;
              vertical-align: middle;
              padding: 23px 0 15px;
            }
            td {
              a { background-position: top 3px right; }
            }
          }
          tr:nth-of-type(3) {
            th { padding: 23px 0 15px; }
            td { padding: 23px 19px 15px; }
          }
        }
      }
      #ContBox03 .contSubBox .subBoxIn {
        .textArea .lists .list {
          span { line-height: 32px; }
        }
      }
    }
  }
  #PageDaichi.pageIndex {
    #Main {
      #ContBox02 .contSubBox {
        table {
          th { padding-top: 7px; }
          td { padding: 22px 20px 15px; }
        }
      }
    }
  }
  #PageFaq.pageEntry #Main {
    #ContBox01 .contSubBox {
      .entryWrap {
        .entryTag { padding: 8.5px 5px 3.5px 7px; }
      }
    }
  }
  #PageContact #Main #ContBox01 .contSubBox .noticeList .notice::before,
  #PageToba #Main #ContBox01 .contSubBox .noticeList .notice::before,
  #PageCleaning #Main #ContBox01 .contSubBox .noticeList .notice::before,
  #PageTenrei #Main #ContBox02 .contSubBox .noticeList .notice::before {
    top: 10px;
  }
  #PageContact.pageIndex #Main {
    #ContBox03 .contSubBox {
      .boxTitle h3 {
        line-height: 1.25;
        width: 300px;
        &:after { right: -54px; }
      }
    }
  }
  #PageToba.pageIndex #Main {
    #ContBox02 .contSubBox .formWrap {
      .formIn {
        tr:nth-of-type(19), tr:last-of-type {
          th { vertical-align: top; }
        }
        th::before { line-height: 32px; }
      }
    }
  }
  #PageToba.pageConfirm #Main {
    #ContBox02 .contSubBox .formWrap {
      .formIn {
        tr:last-of-type {
          th { align-items: flex-start; vertical-align: top; }
        }
        th::before { line-height: 32px; }
      }
      .sendBtn input.wpcf7-back { line-height: 1.5; }
    }
  }
  #PageCleaning.pageIndex #Main {
    #ContBox03 .contSubBox .formWrap {
      .formIn {
        tr:nth-of-type(3), tr:nth-of-type(5), tr:nth-of-type(9), tr:last-of-type {
          th { vertical-align: top; }
        }
        th::before { line-height: 30px; }
      }
    }
  }
  #PageCleaning.pageConfirm #Main {
    #ContBox03 .contSubBox .formWrap {
      .formIn {
        tr:nth-of-type(19), tr:last-of-type {
          th { align-items: flex-start; vertical-align: top; }
        }
        th::before { line-height: 30px; }
      }
      .sendBtn input.wpcf7-back { line-height: 1.5; }
    }
  }
  #PageTenrei.pageIndex #Main {
    #ContBox02 .contSubBox {
      .formIn {
        th::before { line-height: 30px; }
      }
      .formIn01 th::before,
      .formIn02 tr:nth-of-type(2) th::before,
      .formIn02 tr:nth-of-type(3) th::before,
      .formIn02 tr:nth-of-type(4) th::before,
      .formIn02 tr:nth-of-type(5) th::before,
      .formIn02 tr:nth-of-type(6) th::before { top: 6px; }
      .formIn02 tr:nth-of-type(8) td span.religion { margin-bottom: -25px; }
      .formIn02 tr:last-of-type th { vertical-align: top; }
      .btnArea input { line-height: 74px; }
    }
  }
  #PageTenrei.pageConfirm #Main {
    #ContBox02 .contSubBox {
      .formIn {
        tr { padding: 22px 0 17px !important; }
        th::before {
          top: 0;
          line-height: 30px;
        }
      }
      .sendBtn {
        input.wpcf7-submit { line-height: 74px; }
        input.wpcf7-back { line-height: 1.5; }
      }
    }
  }
}

//Google Chrome / Safari / Opera
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .formWrap.formIn {
    tr td {
      input.wpcf7-form-control::placeholder,
      textarea.wpcf7-form-control::placeholder {
        color: #888 !important;
      }
    }
  }
}

//Safari
_::-webkit-full-page-media, _:future, :root .pageEntry .btnWrap .prev span {
    background-position: left top 3px;
}
_::-webkit-full-page-media, _:future, :root .pageEntry .btnWrap  .next span {
    background-position: right top 2px;
}

/* Firefox */
@-moz-document url-prefix() {
   #PageTitle h2 br.sp_display {
    display: none;
  }
}

@media screen and(max-width: 499.99px) {
  .showBox .scrollBox {
    padding-bottom: 15px;
  }
  /*スクロールバー全体*/
  .showBox .scrollBox::-webkit-scrollbar {
    height: 8px;
  }
  /*スクロールバーの軌道*/
  .showBox .scrollBox::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #efeeea;
  }
  /*スクロールバーの動く部分*/
  .showBox .scrollBox::-webkit-scrollbar-thumb {
    background-color: #46ae72;
    border-radius: 8px;
    width: 232px;
  }
}

@media screen and(min-width: 522.99px) {
  #PageToba.pageIndex {
    #Main #ContBox02 {
      .formWrap .formIn {
        tr:nth-of-type(19) {
          .first { margin-bottom: 10px; }
        }
      }
    }
  }
}